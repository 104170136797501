import { fetchOpened } from '@/api/business/bankstatement'
import { useQuery } from '@tanstack/react-query'

export const BankStatementOpenedKeys = {
    all: ['bankstatementopened'] as const,
    lists: () => [...BankStatementOpenedKeys.all, 'list'] as const,
    list: (page: number, perPage: number, filters: any) =>
        [...BankStatementOpenedKeys.lists(), page, perPage, filters] as const,
    details: () => [...BankStatementOpenedKeys.all, 'detail'] as const,
    detail: (params: string) => [...BankStatementOpenedKeys.details(), params] as const,
}

export const useBankStatmentOpenedQuery = ({X_CHAVE, X_TYPE, enableStatus}) => {
    return useQuery({
        enabled: enableStatus,
        queryFn : () => fetchOpened({X_CHAVE, X_TYPE}),
        queryKey: BankStatementOpenedKeys.lists(),
    })
}
