import Button from '@/components/Button'
import { TypographyH1, TypographyMuted } from '@/components/ui/typography'

interface DadosMestreHeaderProps {
    title: string
    selectedRows?: number
    onDelete?: () => void
}

const DadosMestreHeader = ({
    title,
    selectedRows,
    onDelete,
}: DadosMestreHeaderProps) => {
    return (
        <div className="space-y-0.5 flex gap-4 items-center	">
            <TypographyH1 className="text-xl font-semibold">
                {title}
            </TypographyH1>
            {selectedRows && selectedRows > 0 ? (
                <div className="flex items-center gap-2">
                    <TypographyMuted>
                        {selectedRows} itens selecionados
                    </TypographyMuted>
                    <Button variant="ghost" size="none" onClick={onDelete}>
                        Excluir
                    </Button>
                </div>
            ) : null}
        </div>
    )
}

export default DadosMestreHeader
