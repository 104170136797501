import { TableLayout } from '@/types/TableLayout'
import instance from '..'
import {
    CreateTableLayoutProps,
    DeleteLayoutProps,
    FetchTableLayoutsProps,
    UpdateLayoutProps,
} from './type'

const BASE_URL = '/layout-tabela'

export const fetchTableLayouts = async ({
    tableId,
}: FetchTableLayoutsProps) => {
    const response = await instance.post<TableLayout[]>(BASE_URL, {
        DD_CHAVE_TABELA: tableId,
    })

    return response.data
}

export const fetchUserTableLayouts = async ({
    tableId,
}: FetchTableLayoutsProps) => {
    const response = await instance.post<TableLayout[]>(`${BASE_URL}/usuario`, {
        DD_CHAVE_TABELA: tableId,
    })

    return response.data
}

// export const fetchTableLayouts = async ({
//     tableId,
// }: FetchTableLayoutsProps) => {
//     const response = await instance.post<TableLayout[]>(`${BASE_URL}/tabela`, {
//         DD_CHAVE_TABELA: tableId,
//     })
//     return response.data
// }

export const createTableLayout = async ({
    layout,
    layoutName,
    tableId,
}: CreateTableLayoutProps) => {
    const response = await instance.put(BASE_URL, {
        DD_CHAVE_TABELA: tableId,
        DS_LAYOUT: layoutName,
        LAYOUT: layout,
    })

    return response.data
}

export const updateTableLayout = async (props: UpdateLayoutProps) => {
    const response = await instance.patch(BASE_URL, { ...props })

    return response.data
}

export const deleteTableLayout = async ({ id }: DeleteLayoutProps) => {
    const response = await instance.delete(BASE_URL, {
        data: { SK_LAYOUT_TABELA: id },
    })

    return response.data
}
