import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { useProductQuery } from '@/queries/useProductQuery'
import { Product } from '@/types/Product'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'
import DadosMestreHeader from '../components/DadosMestreHeader' 
import DadosMestreHeaderActions from '../components/DadosMestreHeaderActions'  
import DefaultColumn from '@/components/Table/components/DefaultColumn' 
import { useTable } from '@/hooks/useTable'
import { useProductSpreadsheets } from './useProductSpreadsheets'

export type ProductTable = TableData<Product>

const columnHelper = createColumnHelper<Product>()

const MasterDataProduct = () => {
    const {
        table,
        tableState,
        selectedRows, 
        onClickMultiDelete, 
        setTable,
    } = useTable<Product>('')

    const { onDownload } = useProductSpreadsheets() 

    const { data, isFetching, isPending, refetch } = useProductQuery()

    const memoData = useMemo(() => data || [], [data])
    const memoColumns = useMemo(
        () => [ 
            columnHelper.accessor('NK_PRODUTO', {
                id: 'NK_PRODUTO',
                header: 'Cod. Produto',
                size: 130,
            }), 
            columnHelper.accessor('DS_PRODUTO', {
                id: 'DS_PRODUTO',
                header: 'Produto',
                size: 400,
            }), 
            columnHelper.accessor('DD_TIPO', {
                id: 'DD_TIPO',
                header: 'Tipo',
                size: 100,
            }), 
            columnHelper.accessor('DD_UNIDADE_MEDIDA', {
                id: 'DD_UNIDADE_MEDIDA',
                header: 'U.M.',
                size: 80,
            }),  
            columnHelper.accessor('DD_NCM', {
                id: 'DD_NCM',
                header: 'NCM',
                size: 100,
            }),  
        ],
        []
    )

    return (
        <div className="w-full h-full p-4 overflow-hidden">
            <Table<ProductTable>
                data={memoData}
                columns={memoColumns}
                getRowId={(row) => row.NK_PRODUTO.toString()}
                isLoading={isPending}
                isFetching={isFetching}
                getTableInstance={(table) => setTable(table)}   
                tableState={tableState}  
                tableHeader={
                    <DadosMestreHeader
                        title={`Produtos`}
                        onDelete={onClickMultiDelete}
                        selectedRows={
                            table
                                ?.getFilteredRowModel()
                                .flatRows.filter((row) => selectedRows[row.id])
                                .length
                        }
                    />
                }
                tableActions={
                    <DadosMestreHeaderActions
                        onDownload={() => table && onDownload(table)}
                        onRefech={refetch}
                    />
                } 
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultColumn>{getValue() as string}</DefaultColumn>
                    ),
                }}
            /> 
        </div>
    )
}

export default MasterDataProduct
