import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'

import { useToast } from '@/components/ui/use-toast'
import Button from '@/components/Button'
import { useMutation } from '@tanstack/react-query'

import { fetchUpdateDataBase } from '@/api/business/updatedatabase'
import { isAxiosError } from 'axios'
import { DatePicker } from '@/components/DatePicker'
import React from 'react'


interface CompleteDataBaseProps {
    isOpen: boolean
    onClose: () => void
}

const UnsettledSecuritiesDataBase = ({ isOpen, onClose }: CompleteDataBaseProps) => { 
    const [date, setDate] = React.useState<Date>(new Date())
    const { toast } = useToast()
    const { mutate, isPending: mutateLoading } = useMutation({
        mutationFn: fetchUpdateDataBase,
        onSuccess: (res) => { 
            toast({
                title: res.response,
                variant: res.status == 202 ? 'warning' : 'success'
            })
            onClose()
        },
        onError: (err) => { 
            let errorMessage = 'Erro ao ataualizar, entre em contato com o Desenvolvimento!'
            
            if (isAxiosError(err)) {
                errorMessage = err.response?.data.response || errorMessage
            }

            toast({
                title: 'Erro ao atualizar a base.',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    }) 
    
    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Atualização Títulos em Aberto.</DialogTitle>
                </DialogHeader>
                <div className="pt-4">
                    <p>
                        Realmente deseja iniciar uma atualização dos Títulos em Aberto? 
                    </p>
                    <div className="space-y-2 flex flex-col mt-2">
                        <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">Selecione a Data Base</label>
                        <DatePicker date={date} setDate={(data) => {
                            if(data) setDate(data)
                        }}/>
                    </div>
                </div>
                <DialogFooter>
                    <Button onClick={onClose} variant="ghost">
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => mutate({TYPE: 'UNSETTLED_SECURITIES', BASE_DATE: date})}
                        isLoading={mutateLoading}
                    >
                        Confirmar
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default UnsettledSecuritiesDataBase
