import { fetchPositionSumarry } from '@/api/business/unsettledSecurities'
import { useQuery } from '@tanstack/react-query'

export const UnsettledSecuritiesPositionSummaryKeys = {
    all: ['unsettledsecuritiespositionsummary'] as const,
    lists: () => [...UnsettledSecuritiesPositionSummaryKeys.all, 'list'] as const,
    list: (page: number, perPage: number, filters: any) =>
        [...UnsettledSecuritiesPositionSummaryKeys.lists(), page, perPage, filters] as const,
    details: () => [...UnsettledSecuritiesPositionSummaryKeys.all, 'detail'] as const,
    detail: (params: string) => [...UnsettledSecuritiesPositionSummaryKeys.details(), params] as const,
}

export const useUnsettledSecuritiesPositionSummaryQuery = () => {
    return useQuery({
        queryFn: fetchPositionSumarry,
        queryKey: UnsettledSecuritiesPositionSummaryKeys.lists(),
    })
}
