import { fetchRoutsResale } from '@/api/business/resale'
import { useQuery } from '@tanstack/react-query'

export const RoutsResaleKeys = {
    all: ['routsresale'] as const,
    lists: () => [...RoutsResaleKeys.all, 'list'] as const,
    list: (page: number, perPage: number, filters: any) =>
        [...RoutsResaleKeys.lists(), page, perPage, filters] as const,
    details: () => [...RoutsResaleKeys.all, 'detail'] as const,
    detail: (params: string) => [...RoutsResaleKeys.details(), params] as const,
}

export const useRoutsResaleQuery = () => {
    return useQuery({
        queryFn: fetchRoutsResale,
        queryKey: RoutsResaleKeys.lists(),
    })
}
