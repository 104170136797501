import instance from '@/api/business' 
import { Product } from './type'

const BASE_URL = '/product/crweb/product'

export const fetchProduct = async () => {
    const response = await instance.post<Product[]>(BASE_URL)

    return response.data
}
 