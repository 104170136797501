import { Table } from '@tanstack/react-table'
import { CustomerTable } from '.'
import { utils, writeFile } from 'xlsx'
import { Customer } from '@/types/Customer'
  
export const useCustomerSpreadsheets = () => {
    const getTableRows = (table: Table<CustomerTable>): Partial<Customer>[] =>
        table.getCoreRowModel().rows.map((row) => {
            const obj = {} as Record<string, any>

            obj['Cod. Cliente'] = row.original.NK_CLIENTE
            obj['Loja'] = row.original.DD_LOJA
            obj['Razão Social'] = row.original.DS_RAZAO_SOCIAL
            obj['Nome Fantasia'] = row.original.DS_NOME_FANTASIA
            obj['CEP'] = row.original.DD_CEP
            obj['UF'] = row.original.DD_ESTADO
            obj['Municipio'] = row.original.DD_MUNICIPIO
            obj['Bairro'] = row.original.DD_BAIRRO
            obj['Endereço'] = row.original.DD_ENDERECO
             
            return obj
        })

    const onDownload = (tableInstance: Table<CustomerTable>) => {
         
        const rows = getTableRows(tableInstance)
        const worksheet = utils.json_to_sheet(rows)
        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Clientes')

        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear().toString();
        const currentDateFormated =  day + month + year;

        writeFile(workbook, `Cliente_${currentDateFormated}.xlsx`, {
            compression: true,
        })
    }

    return {
        onDownload,
    }
}
