import instance from '@/api/business' 
import { Resale, DeleteResaleProps, CreatedResaleProps, UpdateResaleProps, RoutsResale, OwnerResale } from './type'

const BASE_URL = '/resale/crweb/resale'

export const fetchResale = async () => {
    const response = await instance.post<Resale[]>(BASE_URL)

    return response.data
}

export const createResale = async (data: CreatedResaleProps) => {
    const response = await instance.put(BASE_URL, data)

    return response.data

}
export const updateResale = async (data: UpdateResaleProps) => {
    const response = await instance.patch(BASE_URL, data)

    return response.data
}

export const deleteResale = async (props: DeleteResaleProps) => {
    const response = await instance.delete(BASE_URL, {
        data: props,
    })

    return response.data
}

export const fetchRoutsResale = async () => {
    const response = await instance.post<RoutsResale[]>('/resale/crweb/routsresale')

    return response.data
}

export const fetchOwnerResale = async () => {
    const response = await instance.post<OwnerResale[]>('/resale/crweb/ownerresale')

    return response.data
}