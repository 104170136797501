import { fetchProduct } from '@/api/business/product'
import { useQuery } from '@tanstack/react-query'

export const ProductsKeys = {
    all: ['products'] as const,
    lists: () => [...ProductsKeys.all, 'list'] as const,
    list: (page: number, perPage: number, filters: any) =>
        [...ProductsKeys.lists(), page, perPage, filters] as const,
    details: () => [...ProductsKeys.all, 'detail'] as const,
    detail: (params: string) => [...ProductsKeys.details(), params] as const,
}

export const useProductQuery = () => {
    return useQuery({
        queryFn: fetchProduct,
        queryKey: ProductsKeys.lists(),
    })
}
