import {
    Card,
    CardContent,
    CardDescription, 
    CardHeader,
    CardTitle,
  } from "@/components/ui/card"
  
  type CardSummaryProps = {
    title: string 
    value: string
    observation?: string
  }
  export default function CardSummary({title, value, observation} : CardSummaryProps) {
    return (
        <Card>
            <CardHeader className="py-4 px-6">
                <CardDescription>{title}</CardDescription> 
                <CardTitle className="text-xl">{value}</CardTitle>
            </CardHeader>
            {observation && (
                <CardContent>
                <div className="text- text-muted-foreground">
                    {observation}
                </div>
            </CardContent>
            )
            
            }
        </Card>
    )
  }
  