import { Table } from '@tanstack/react-table'
import { HistoryPaymentTable } from '.'
import { utils, writeFile } from 'xlsx'
import { HistoryPayment } from '@/types/BankStatement'
import { getDateFromId } from '@/utils/date'
  
export const useHistoryPaymentSpreadsheets = () => {
    const getTableRows = (table: Table<HistoryPaymentTable>): Partial<HistoryPayment>[] =>
        table.getCoreRowModel().rows.map((row) => {
            const obj = {} as Record<string, any> 

            obj['Revenda'] = row.original.DS_ROTA
            obj['Cod. Rota'] = row.original.DD_ROTA
            obj['Empresa'] = row.original.ABREVIATURA_EMPRESA
            obj['Num. Documento'] = row.original.DD_NUMERO_DOCUMENTO
            obj['Dt. Emissão'] = getDateFromId(Number(row.original.SK_EMISSAO))
            obj['Dt. Vencimento'] = getDateFromId(Number(row.original.SK_VENCIMENTO_REAL))
            obj['Dt. Baixa'] = getDateFromId(Number(row.original.SK_BAIXA))
            obj['Dias Juros'] = row.original.DD_DIAS_JUROS
            obj['Valor Título'] = Number(row.original.VL_NF)
            obj['Vl. Pago a Vencer'] = Number(row.original.VL_PAGO_A_VENCER)
            obj['Vl. Pago Vencido'] = Number(row.original.VL_PAGO_VENCIDO)
            obj['Vl. Pago'] = Number(row.original.VL_BAIXADO)
            obj['Vl. Juros Pago'] = Number(row.original.VL_JUROS_BAIXADO)
            obj['Vl. Multa Pago'] = Number(row.original.VL_MULTA_BAIXADA)
            obj['Vl. Original'] = Number(row.original.VL_ORIGINAL)
            obj['ID Carga'] = row.original.DD_IDCARGA
            
            return obj
        })

    const onDownload = (tableInstance: Table<HistoryPaymentTable>) => {
        console.log(tableInstance)
        const rows = getTableRows(tableInstance)
        const colluns = Object.keys(rows[0]).length;

        const formatDate = 'dd/mm/yyyy'
        const formatMoneyAccounting = '_-* #,##0.00_-;-* #,##0.00_-;_-* "-"??_-;_-@_-'

        const worksheet = utils.aoa_to_sheet([[]]);
         
        worksheet['H1'] =  { t: 's', v: 'Totais: ' }
        worksheet['I1'] =  { t: 'n', f: `SUBTOTAL(9, I3:I${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['J1'] =  { t: 'n', f: `SUBTOTAL(9, J3:J${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['K1'] =  { t: 'n', f: `SUBTOTAL(9, K3:K${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['L1'] =  { t: 'n', f: `SUBTOTAL(9, L3:L${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['M1'] =  { t: 'n', f: `SUBTOTAL(9, M3:M${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['N1'] =  { t: 'n', f: `SUBTOTAL(9, N3:N${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['O1'] =  { t: 'n', f: `SUBTOTAL(9, O3:O${rows.length + 3})`, z: formatMoneyAccounting }
        
        utils.sheet_add_json(worksheet, rows, {
            origin: { r: 1, c: 0 } 
        });

        for (let i = 3; i <= rows.length + 2; i++) { 
            
            worksheet[`E${i}`].z = formatDate
            worksheet[`F${i}`].z = formatDate
            worksheet[`G${i}`].z = formatDate

            worksheet[`H${i}`].z = '#,##0'
            worksheet[`H${i}`].t = 'n'

            worksheet[`I${i}`].z = formatMoneyAccounting
            worksheet[`J${i}`].z = formatMoneyAccounting
            worksheet[`K${i}`].z = formatMoneyAccounting 
            worksheet[`L${i}`].z = formatMoneyAccounting 
            worksheet[`M${i}`].z = formatMoneyAccounting 
            worksheet[`N${i}`].z = formatMoneyAccounting 
            worksheet[`O${i}`].z = formatMoneyAccounting 
        }

        worksheet["!cols"] =  Array(colluns).fill({ wch: 20 });

        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Historico')

        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear().toString();
        const currentDateFormated =  day + month + year;

        writeFile(workbook, `Extrato_Revenda_Historico_Pagamento_${currentDateFormated}.xlsx`, {
            compression: true,
        })
    }

    return {
        onDownload,
    }
}
