import { fetchOwnerResale } from '@/api/business/resale'
import { useQuery } from '@tanstack/react-query'

export const OwnerResaleKeys = {
    all: ['ownerresale'] as const,
    lists: () => [...OwnerResaleKeys.all, 'list'] as const,
    list: (page: number, perPage: number, filters: any) =>
        [...OwnerResaleKeys.lists(), page, perPage, filters] as const,
    details: () => [...OwnerResaleKeys.all, 'detail'] as const,
    detail: (params: string) => [...OwnerResaleKeys.details(), params] as const,
}

export const useOwnerResaleQuery = () => {
    return useQuery({
        queryFn: fetchOwnerResale,
        queryKey: OwnerResaleKeys.lists(),
    })
}
