import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'

import { Row } from '@tanstack/react-table'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/components/ui/use-toast'
import { isAxiosError } from '@/api/business'
import { ResaleTable } from '../..'
import { updateResale } from '@/api/business/resale'
import { ResaleKeys } from '@/queries/useResaleQuery'
import Button from '@/components/Button'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "@/components/ui/select"
import { Input } from '@/components/ui/input'
import { useEffect } from 'react'
import { Textarea } from '@/components/ui/textarea'

interface EditDialogProps {
    row: Row<ResaleTable>
    isOpen: boolean
    onClose: () => void
}

const EditDialog = ({ row, isOpen, onClose }: EditDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const schema = z.object({
        DS_ROTA: z.string().min(1, {
            message: 'O campo Nome da Rota é obrigatório',
        }),
        DD_ROTA: z.string(),
        DD_TIPO: z.string(),
        NM_PROPRIETARIO: z.string(),
        DD_CLIENTE: z.string(),
        DD_LOJA: z.string(),
        NM_CLIENTE: z.string(),
        DS_REVENDA_GRADE: z.string(),
        NM_REVENDA: z.string(),
        NM_MUNICIPIO: z.string(),
        NM_ENDERECO: z.string(),
        NM_BAIRRO: z.string(),
        DD_CEP: z.string(),
        DD_ESTADO: z.string(),
        DD_CNPJ: z.string(),
        DD_APARECER_LIMITE: z.string(),
        DD_PRAZO: z.string(),
        DD_VERSAO: z.string(),
        DD_LIMITE: z.string(),
        DD_TIPO_GARANTIA: z.string(),
        DD_VALOR: z.string(),
        DS_OBSERVACAO1: z.string(),
        DS_OBSERVACAO2: z.string(),
        DD_CADASTRO_LIMITE: z.string()
    })

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            DS_ROTA: row.original.DS_ROTA || "",
            DD_ROTA: row.original.DD_ROTA || "",
            DD_TIPO: row.original.DD_TIPO || "",
            NM_PROPRIETARIO: row.original.NM_PROPRIETARIO || "",
            DD_CLIENTE: row.original.DD_CLIENTE || "",
            DD_LOJA: row.original.DD_LOJA || "",
            NM_CLIENTE: row.original.NM_CLIENTE || "",
            DS_REVENDA_GRADE: row.original.DS_REVENDA_GRADE || "",
            NM_REVENDA: row.original.NM_REVENDA || "",
            NM_MUNICIPIO: row.original.NM_MUNICIPIO || "",
            NM_ENDERECO: row.original.NM_ENDERECO || "",
            NM_BAIRRO: row.original.NM_BAIRRO || "",
            DD_CEP: row.original.DD_CEP || "",
            DD_ESTADO: row.original.DD_ESTADO || "",
            DD_CNPJ: row.original.DD_CNPJ || "",
            DD_APARECER_LIMITE: row.original.DD_APARECER_LIMITE || "",
            DD_PRAZO: row.original.DD_PRAZO || "",
            DD_VERSAO: row.original.DD_VERSAO || "",
            DD_LIMITE: row.original.DD_LIMITE || "",
            DD_TIPO_GARANTIA: row.original.DD_TIPO_GARANTIA || "",
            DD_VALOR: row.original.DD_VALOR || "",
            DS_OBSERVACAO1: row.original.DS_OBSERVACAO1 || "",
            DS_OBSERVACAO2: row.original.DS_OBSERVACAO2 || "",
            DD_CADASTRO_LIMITE: row.original.DD_CADASTRO_LIMITE || ""
        },
    })

    useEffect(() => {
        form.reset({
            DS_ROTA: row.original.DS_ROTA || "",
            DD_ROTA: row.original.DD_ROTA || "",
            DD_TIPO: row.original.DD_TIPO || "",
            NM_PROPRIETARIO: row.original.NM_PROPRIETARIO || "",
            DD_CLIENTE: row.original.DD_CLIENTE || "",
            DD_LOJA: row.original.DD_LOJA || "",
            NM_CLIENTE: row.original.NM_CLIENTE || "",
            DS_REVENDA_GRADE: row.original.DS_REVENDA_GRADE || "",
            NM_REVENDA: row.original.NM_REVENDA || "",
            NM_MUNICIPIO: row.original.NM_MUNICIPIO || "",
            NM_ENDERECO: row.original.NM_ENDERECO || "",
            NM_BAIRRO: row.original.NM_BAIRRO || "",
            DD_CEP: row.original.DD_CEP || "",
            DD_ESTADO: row.original.DD_ESTADO || "",
            DD_CNPJ: row.original.DD_CNPJ || "",
            DD_APARECER_LIMITE: row.original.DD_APARECER_LIMITE || "",
            DD_PRAZO: row.original.DD_PRAZO || "",
            DD_VERSAO: row.original.DD_VERSAO || "",
            DD_LIMITE: row.original.DD_LIMITE || "",
            DD_TIPO_GARANTIA: row.original.DD_TIPO_GARANTIA || "",
            DD_VALOR: row.original.DD_VALOR || "",
            DS_OBSERVACAO1: row.original.DS_OBSERVACAO1 || "",
            DS_OBSERVACAO2: row.original.DS_OBSERVACAO2 || "",
            DD_CADASTRO_LIMITE: row.original.DD_CADASTRO_LIMITE || ""
        })
    }, [row.original])

    const { mutate, isPending: mutateLoading } = useMutation({
        mutationFn: updateResale,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ResaleKeys.lists() })
            toast({
                title: 'Revenda atualizado com sucesso',
            })
            onClose()
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível editar a Revenda. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao editar Revenda',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const onSubmit = (data: z.infer<typeof schema>) => {
        mutate({
            SK_REVENDA: row.original.SK_REVENDA,
           ...data
        })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className='max-w-[1000px] overflow-auto h-3/4'>
                <DialogHeader className='px-2'>
                    <DialogTitle>Editar Revenda</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-2 px-2 overflow-hidden overflow-y-auto"
                    >
                        <div className='grid grid-cols-[3fr,1fr,1fr,3fr] gap-4'>
                            <FormField
                                name="DS_ROTA"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Nome rota</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="DD_ROTA"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Cod. Rota</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="DD_TIPO"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Tipo</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="NM_PROPRIETARIO"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Proprietário</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className='grid grid-cols-[1fr,1fr,5fr] gap-4'>
                            <FormField
                                name="DD_CLIENTE"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Cod. Cliente</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="DD_LOJA"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Loja Cliente</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="NM_CLIENTE"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Nome Cliente</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            /> 
                        </div>
                        
                        <div className='grid grid-cols-[1fr,1fr] gap-4'> 
                            <FormField
                                name="DS_REVENDA_GRADE"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Revenda na Grade</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="NM_REVENDA"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Nome Revenda</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>

                        <div className='grid grid-cols-[1fr,2fr] gap-4'> 
                            <FormField
                                name="NM_MUNICIPIO"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Municipio</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="NM_ENDERECO"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Endereço</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            /> 
                        </div>
                        
                        <div className='grid grid-cols-[6fr,2fr,1fr] gap-4'> 
                            <FormField
                                name="NM_BAIRRO"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Bairro</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="DD_CEP"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>CEP</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="DD_ESTADO"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>UF</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className='grid grid-cols-[2fr,1fr,1fr,1fr,1fr] gap-4'>
                            <FormField
                                name="DD_CNPJ"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>CNPJ</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="DD_APARECER_LIMITE"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Aparecer limite</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="DD_PRAZO"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Prazo</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="DD_VERSAO"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Versão</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="DD_CADASTRO_LIMITE"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Cadastro no Limite</FormLabel> 
                                        <Select onValueChange={(value) => field.onChange(value)} defaultValue={field.value.toString()}>
                                            <FormControl>
                                                <SelectTrigger>
                                                <SelectValue placeholder="" />
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                <SelectItem value='NÃO'>NÃO</SelectItem>
                                                <SelectItem value="SIM">SIM</SelectItem>
                                            </SelectContent>
                                        </Select>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            /> 
                        </div>
                        <div className='grid grid-cols-[1fr,1fr,4fr] gap-4'>
                            <FormField
                                name="DD_LIMITE"
                                control={form.control} 
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Limite</FormLabel>
                                        <FormControl>
                                            <Input {...field} type='number'/>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            /> 
                            <FormField
                                name="DD_TIPO_GARANTIA"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Tipo Garantia</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            /> 
                            <FormField
                                name="DD_VALOR"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Valor</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            /> 
                        </div> 
                        <div className='grid grid-cols-[1fr,1fr] gap-4'>
                            <FormField
                                name="DS_OBSERVACAO1"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>1º Observação</FormLabel>
                                        <FormControl>
                                            <Textarea {...field} /> 
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="DS_OBSERVACAO2"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>2º Observação</FormLabel>
                                        <FormControl>
                                            <Textarea {...field} /> 
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <DialogFooter>
                            <Button
                                type="button"
                                onClick={onClose}
                                variant="ghost"
                            >
                                Cancelar
                            </Button>
                            <Button type="submit" isLoading={mutateLoading}>
                                Confirmar
                            </Button>
                        </DialogFooter> 
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default EditDialog
