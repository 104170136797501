import { fetchLastUpdate } from '@/api/business/lastupdate'
import { useQuery } from '@tanstack/react-query'

export const LastUpdateKeys = {
    all: ['lastupdate'] as const,
    lists: () => [...LastUpdateKeys.all, 'list'] as const,
    list: (page: number, perPage: number, filters: any) =>
        [...LastUpdateKeys.lists(), page, perPage, filters] as const,
    details: () => [...LastUpdateKeys.all, 'detail'] as const,
    detail: (params: string) => [...LastUpdateKeys.details(), params] as const,
}

export const useLastUpdateQuery = () => {
    return useQuery({
        queryFn: fetchLastUpdate,
        queryKey: LastUpdateKeys.lists(),
    })
}
