import { Newspaper , LucideIcon, Settings, Cog, Landmark, Home, Receipt} from 'lucide-react'

export const SIDENAV_ITEMS: SidenavItems[] = [
    {
        id: 1,
        label: 'Inicio',
        Icon: Home ,
        to: '/'
    },
    {
       
        id: 2,
        label: 'Títulos',
        Icon: Newspaper,
        subItems: [
            {
                id: 2.1,
                label: 'Posição 1',
                to: '/bonds/position1',
            }, 
            {
                id: 2.2,
                label: 'Posição 2',
                to: '/bonds/position2',
            }, 
            {
                id: 2.3,
                label: 'Posição 3',
                to: '/bonds/position3',
            }, 
            {
                id: 2.4,
                label: 'Posição Total',
                to: '/bonds/totalposition',
            }, 
        ],
    },
    {
        id: 3,
        label: 'Extrato Revenda',
        Icon: Receipt,
        subItems: [
            {
                id: 3.1,
                label: 'Em Aberto',
                to: '/bankstatement/opened'
            },
            {
                id: 3.2,
                label: 'Movimentações',
                to: '/bankstatement/movimentation'
            },
            {
                id: 3.3,
                label: 'Histórico de Títulos Baixados',
                to: '/bankstatement/historypayments'
            },
            {
                id: 3.4,
                label: 'Resumo do Saldo',
                to: '/bankstatement/balancesummary'
            }
        ]
    },
    {
        id: 4,
        label: 'Limite Banco',
        Icon: Landmark, 
        to: '/bank/limitbank'
            
    },
    {
        id: 5,
        label: 'Dados Mestre',
        Icon: Settings,
        subItems: [
            {
                id: 5.1,
                label: 'Cliente',
                to: '/masterdata/customer'
            },
            {
                id: 5.2,
                label: 'Revenda',
                to: '/masterdata/resale'
            },
            {
                id: 5.3,
                label: 'Produto',
                to: '/masterdata/product'
            }
        ]
    },
    
    {
        id: 6,
        label: 'Configurações',
        Icon: Cog,
        subItems: [
            {
                id: 6.1,
                label: 'Atualização de Base',
                to: '/settings/update'
            }, 
        ]
    }
]

export type SidenavItems = {
    id: number
    label: string
    to?: string
    Icon?: LucideIcon
    subItems?: SidenavItems[]
}
