import { useTotalPositionQuery } from "@/queries/useTotalPositionQuery"
import { 
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import { Link } from 'react-router-dom' 
import { useMemo, useState } from 'react'
import Table from '@/components/Table'
import { createColumnHelper } from '@tanstack/react-table'
import { TableData } from '@/components/Table/type'
import { UnsettledSecuritiesTotalPosition } from "@/types/UnsettledSecurities"
import DefaultColumn from '@/components/Table/components/DefaultColumn'  
import { currencyFormat } from "@/utils/stringFormatter"
import ButtonWithTooltip from "@/components/ButtonWithTooltip"
import { ArrowDownToLine, FilterX, RefreshCcw } from "lucide-react"
import Button from "@/components/Button"
import { useTotalPositionSpreadsheets } from "./useTotalPositionSpreadsheets"
import { TypographyH4 } from "@/components/ui/typography"
import { useTable } from "@/hooks/useTable"

export type UnsettledSecuritiesTable = TableData<UnsettledSecuritiesTotalPosition>

type scolumnsTableVisibleProps = {
    id: number,
    description: string,
    selected: boolean,
    columns: string[]
    
}

const columnHelper = createColumnHelper<UnsettledSecuritiesTotalPosition>()

const TotalPosition = () => {
    const {
        table,
        tableState, 
        setTable,
    } = useTable<UnsettledSecuritiesTotalPosition>('')

    const { data, isFetching, isPending, refetch } = useTotalPositionQuery()
    
    const standardColumnsVisible = [
        {id: 0, selected: true, description: "Informações Extra", columns: ["DD_TIPO_GARANTIA","DD_VALOR","DS_OBSERVACAO1","DS_OBSERVACAO2"]},
        {id: 1, selected: true, description: "Posição 1", columns: ["VL_A_VENCER_P1","VL_VENCIDO_P1","VL_VENCIDO_HOJE_P1","VL_VENCIDO_E_A_VENCER_P1","VL_A_VENCER_ACORDO_P1","VL_VENCIDO_ACORDO_P1","VL_VENCIDO_ACORDO_HOJE_P1","VL_VENCIDO_E_A_VENCER_ACORDO_P1",]},
        {id: 2, selected: true, description: "Posição 2", columns: ["VL_A_VENCER_P2","VL_VENCIDO_P2","VL_VENCIDO_HOJE_P2","VL_JUROS_PELO_PRAZO_P2","VL_MULTA_P2","VL_TOTAL_COM_JUROS_P2","VL_A_VENCER_ACORDO_P2","VL_VENCIDO_ACORDO_P2","VL_VENCIDO_ACORDO_HOJE_P2","VL_JUROS_PELO_PRAZO_ACORDO_P2","VL_MULTA_ACORDO_P2","VL_TOTAL_COM_JUROS_ACORDO_P2"]},
        {id: 3, selected: true, description: "Posição 3", columns: ["VL_A_VENCER_P3","VL_VENCIDO_P3","VL_VENCIDO_HOJE_P3","VL_JUROS_PELO_PRAZO_P3","VL_MULTA_P3","VL_TOTAL_COM_JUROS_P3","VL_A_VENCER_ACORDO_P3","VL_VENCIDO_ACORDO_P3","VL_VENCIDO_ACORDO_HOJE_P3","VL_JUROS_PELO_PRAZO_ACORDO_P3","VL_MULTA_ACORDO_P3","VL_TOTAL_COM_JUROS_ACORDO_P3"]},
        {id: 4, selected: true, description: "Extrato", columns: ["EXTRATO","EXTRATO_MENOS_VENCIDOS","EXTRATO_MENOS_VENCIDOS_E_A_VENCER"]},
        {id: 5, selected: true, description: "Valores Totais", columns: ["TOTAL_A_VENCER","TOTAL_VENCIDO","TOTAL_EM_ABERTO","TOTAL_A_VENCER_ACORDO","TOTAL_VENCIDO_ACORDO","TOTAL_EM_ABERTO_ACORDO"]},
        {id: 6, selected: true, description: "Situações", columns: ["SITUACAO_SEM_ACORDO_LIMITE_DISPONIVEL","SITUACAO_SEM_ACORDO_A_VENCER","SITUACAO_SEM_ACORDO_VENCIDO","SITUACAO_SEM_ACORDO_TOTAL_EM_ABERTO","SITUACAO_SEM_ACORDO_STATUS","SITUACAO_COM_ACORDO_LIMITE_DISPONIVEL","SITUACAO_COM_ACORDO_STATUS","SITUACAO_COM_ACORDO_CHECK_LIMITE","SITUACAO_COM_ACORDO_TEM_SALDO","SITUACAO_COM_ACORDO_INADIMPLENCIA"]},
    ] 
    
    const [columnsTableVisible, setColumnsTableVisible] = useState<scolumnsTableVisibleProps[]>(standardColumnsVisible) 
    
    const { onDownload } = useTotalPositionSpreadsheets()

    const memoData = useMemo(() => data || [], [data])
    const memoColumns = useMemo(
        () => [
            columnHelper.accessor('DS_ROTA', {
                id: 'DS_ROTA',
                header: 'Revenda',
                size: 180,
            }),
            columnHelper.accessor('NM_PROPRIETARIO', {
                id: 'NM_PROPRIETARIO',
                header: 'Proprietário',
                size: 180,
            }),
            columnHelper.accessor('NM_MUNICIPIO', {
                id: 'NM_MUNICIPIO',
                header: 'Município',
                size: 200,
            }),
            columnHelper.accessor('DD_CLIENTE', {
                id: 'DD_CLIENTE',
                header: 'Cod. Cliente',
                size: 120,
            }),
            columnHelper.accessor('DS_REVENDA_GRADE', {
                id: 'DS_REVENDA_GRADE',
                header: 'Cliente',
                size: 240,
            }),
            columnHelper.accessor('DD_ESTADO', {
                id: 'DD_ESTADO',
                header: 'UF',
                size: 70,
            }),
            columnHelper.accessor(({ DD_LIMITE }) =>
                DD_LIMITE
                    ? currencyFormat(Number(DD_LIMITE))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'DD_LIMITE',
                header: 'Limite Crédito',
                meta: {
                    header: {
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                size: 150,
            }),
            columnHelper.accessor('DD_TIPO_GARANTIA', {
                id: 'DD_TIPO_GARANTIA',
                header: 'Tipo Garantia',
                size: 125,
            }),
            columnHelper.accessor('DD_VALOR', {
                id: 'DD_VALOR',
                header: 'Valor',
                size: 80,
            }),
            columnHelper.accessor('DS_OBSERVACAO1', {
                id: 'DS_OBSERVACAO1',
                header: '1º Observação',
                size: 130,
            }),
            columnHelper.accessor('DS_OBSERVACAO2', {
                id: 'DS_OBSERVACAO2',
                header: '2º Observação',
                size: 130,
            }),
            columnHelper.accessor(({ VL_A_VENCER_P1 }) =>
                VL_A_VENCER_P1
                    ? currencyFormat(Number(VL_A_VENCER_P1))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_A_VENCER_P1',
                meta: {
                    header: {
                        className: 'bg-green-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P1 - Total a Vencer',
                size: 155,
            }),
            columnHelper.accessor(({ VL_VENCIDO_P1 }) =>
                VL_VENCIDO_P1
                    ? currencyFormat(Number(VL_VENCIDO_P1))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_VENCIDO_P1',
                meta: {
                    header: {
                        className: 'bg-green-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P1 - Total Vencido',
                size: 155,
            }),
            columnHelper.accessor(({ VL_VENCIDO_HOJE_P1 }) =>
                VL_VENCIDO_HOJE_P1
                    ? currencyFormat(Number(VL_VENCIDO_HOJE_P1))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_VENCIDO_HOJE_P1',
                meta: {
                    header: {
                        className: 'bg-green-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P1 - Vencimento Hoje',
                size: 170,
            }),
            columnHelper.accessor(({ VL_VENCIDO_E_A_VENCER_P1 }) =>
                VL_VENCIDO_E_A_VENCER_P1
                    ? currencyFormat(Number(VL_VENCIDO_E_A_VENCER_P1))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_VENCIDO_E_A_VENCER_P1',
                meta: {
                    header: {
                        className: 'bg-green-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P1 - Total em Aberto',
                size: 170,
            }),
            columnHelper.accessor(({ VL_A_VENCER_ACORDO_P1 }) =>
                VL_A_VENCER_ACORDO_P1
                    ? currencyFormat(Number(VL_A_VENCER_ACORDO_P1))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_A_VENCER_ACORDO_P1',
                meta: {
                    header: {
                        className: 'bg-green-100',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P1 Acordo - Total a Vencer',
                size: 200,
            }),
            columnHelper.accessor(({ VL_VENCIDO_ACORDO_P1 }) =>
                VL_VENCIDO_ACORDO_P1
                    ? currencyFormat(Number(VL_VENCIDO_ACORDO_P1))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_VENCIDO_ACORDO_P1',
                meta: {
                    header: {
                        className: 'bg-green-100',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P1 Acordo - Total Vencido',
                size: 195,
            }),
            columnHelper.accessor(({ VL_VENCIDO_ACORDO_HOJE_P1 }) =>
                VL_VENCIDO_ACORDO_HOJE_P1
                    ? currencyFormat(Number(VL_VENCIDO_ACORDO_HOJE_P1))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_VENCIDO_ACORDO_HOJE_P1',
                meta: {
                    header: {
                        className: 'bg-green-100',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P1 Acordo - Vencimento Hoje',
                size: 215,
            }),
            columnHelper.accessor(({ VL_VENCIDO_E_A_VENCER_ACORDO_P1 }) =>
                VL_VENCIDO_E_A_VENCER_ACORDO_P1
                    ? currencyFormat(Number(VL_VENCIDO_E_A_VENCER_ACORDO_P1))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_VENCIDO_E_A_VENCER_ACORDO_P1',
                meta: {
                    header: {
                        className: 'bg-green-100',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P1 Acordo - Total em Aberto',
                size: 210,
            }),
            columnHelper.accessor(({ VL_A_VENCER_P2 }) =>
                VL_A_VENCER_P2
                    ? currencyFormat(Number(VL_A_VENCER_P2))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_A_VENCER_P2',
                meta: {
                    header: {
                        className: 'bg-sky-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P2 - Total a Vencer',
                size: 155,
            }),
            columnHelper.accessor(({ VL_VENCIDO_P2 }) =>
                VL_VENCIDO_P2
                    ? currencyFormat(Number(VL_VENCIDO_P2))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_VENCIDO_P2',
                meta: {
                    header: {
                        className: 'bg-sky-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P2 - Total Vencido',
                size: 150,
            }),
            columnHelper.accessor(({ VL_VENCIDO_HOJE_P2 }) =>
                VL_VENCIDO_HOJE_P2
                    ? currencyFormat(Number(VL_VENCIDO_HOJE_P2))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_VENCIDO_HOJE_P2',
                meta: {
                    header: {
                        className: 'bg-sky-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P2 - Vencimento Hoje',
                size: 170,
            }),
            columnHelper.accessor(({ VL_JUROS_PELO_PRAZO_P2 }) =>
                VL_JUROS_PELO_PRAZO_P2
                    ? currencyFormat(Number(VL_JUROS_PELO_PRAZO_P2))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_JUROS_PELO_PRAZO_P2',
                meta: {
                    header: {
                        className: 'bg-sky-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P2 - Juros Pelo Prazo',
                size: 170,
            }),
            columnHelper.accessor(({ VL_MULTA_P2 }) =>
                VL_MULTA_P2
                    ? currencyFormat(Number(VL_MULTA_P2))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_MULTA_P2',
                meta: {
                    header: {
                        className: 'bg-sky-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P2 - Total Multa',
                size: 150,
            }),
            columnHelper.accessor(({ VL_TOTAL_COM_JUROS_P2 }) =>
                VL_TOTAL_COM_JUROS_P2
                    ? currencyFormat(Number(VL_TOTAL_COM_JUROS_P2))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_TOTAL_COM_JUROS_P2',
                meta: {
                    header: {
                        className: 'bg-sky-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P2 - Total em Aberto',
                size: 170,
            }),
            columnHelper.accessor(({ VL_A_VENCER_ACORDO_P2 }) =>
                VL_A_VENCER_ACORDO_P2
                    ? currencyFormat(Number(VL_A_VENCER_ACORDO_P2))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_A_VENCER_ACORDO_P2',
                meta: {
                    header: {
                        className: 'bg-sky-100',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P2 Acordo - Total a Vencer',
                size: 200,
            }),
            columnHelper.accessor(({ VL_VENCIDO_ACORDO_P2 }) =>
                VL_VENCIDO_ACORDO_P2
                    ? currencyFormat(Number(VL_VENCIDO_ACORDO_P2))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_VENCIDO_ACORDO_P2',
                meta: {
                    header: {
                        className: 'bg-sky-100',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P2 Acordo - Total Vencido',
                size: 200,
            }),
            columnHelper.accessor(({ VL_VENCIDO_ACORDO_HOJE_P2 }) =>
                VL_VENCIDO_ACORDO_HOJE_P2
                    ? currencyFormat(Number(VL_VENCIDO_ACORDO_HOJE_P2))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_VENCIDO_ACORDO_HOJE_P2',
                meta: {
                    header: {
                        className: 'bg-sky-100',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P2 Acordo - Vencimento Hoje',
                size: 220,
            }),
            columnHelper.accessor(({ VL_JUROS_PELO_PRAZO_ACORDO_P2 }) =>
                VL_JUROS_PELO_PRAZO_ACORDO_P2
                    ? currencyFormat(Number(VL_JUROS_PELO_PRAZO_ACORDO_P2))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_JUROS_PELO_PRAZO_ACORDO_P2',
                meta: {
                    header: {
                        className: 'bg-sky-100',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P2 Acordo - Juros Pelo Prazo',
                size: 210,
            }),
            columnHelper.accessor(({ VL_MULTA_ACORDO_P2 }) =>
                VL_MULTA_ACORDO_P2
                    ? currencyFormat(Number(VL_MULTA_ACORDO_P2))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_MULTA_ACORDO_P2',
                meta: {
                    header: {
                        className: 'bg-sky-100',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P2 Acordo - Total Multa',
                size: 185,
            }),
            columnHelper.accessor(({ VL_TOTAL_COM_JUROS_ACORDO_P2 }) =>
                VL_TOTAL_COM_JUROS_ACORDO_P2
                    ? currencyFormat(Number(VL_TOTAL_COM_JUROS_ACORDO_P2))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_TOTAL_COM_JUROS_ACORDO_P2',
                meta: {
                    header: {
                        className: 'bg-sky-100',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P2 Acordo - Total em Aberto',
                size: 210,
            }),
            columnHelper.accessor(({ VL_A_VENCER_P3 }) =>
                VL_A_VENCER_P3
                    ? currencyFormat(Number(VL_A_VENCER_P3))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_A_VENCER_P3',
                meta: {
                    header: {
                        className: 'bg-purple-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P3 - Total a Vencer',
                size: 155,
            }),
            columnHelper.accessor(({ VL_VENCIDO_P3 }) =>
                VL_VENCIDO_P3
                    ? currencyFormat(Number(VL_VENCIDO_P3))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_VENCIDO_P3',
                meta: {
                    header: {
                        className: 'bg-purple-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P3 - Total Vencido',
                size: 155,
            }),
            columnHelper.accessor(({ VL_VENCIDO_HOJE_P3 }) =>
                VL_VENCIDO_HOJE_P3
                    ? currencyFormat(Number(VL_VENCIDO_HOJE_P3))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_VENCIDO_HOJE_P3',
                meta: {
                    header: {
                        className: 'bg-purple-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P3 - Vencimento Hoje',
                size: 170,
            }),
            columnHelper.accessor(({ VL_JUROS_PELO_PRAZO_P3 }) =>
                VL_JUROS_PELO_PRAZO_P3
                    ? currencyFormat(Number(VL_JUROS_PELO_PRAZO_P3))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_JUROS_PELO_PRAZO_P3',
                meta: {
                    header: {
                        className: 'bg-purple-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P3 - Juros Pelo Prazo',
                size: 170,
            }),
            columnHelper.accessor(({ VL_MULTA_P3 }) =>
                VL_MULTA_P3
                    ? currencyFormat(Number(VL_MULTA_P3))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_MULTA_P3',
                meta: {
                    header: {
                        className: 'bg-purple-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P3 - Total Multa',
                size: 150,
            }),
            columnHelper.accessor(({ VL_TOTAL_COM_JUROS_P3 }) =>
                VL_TOTAL_COM_JUROS_P3
                    ? currencyFormat(Number(VL_TOTAL_COM_JUROS_P3))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_TOTAL_COM_JUROS_P3',
                meta: {
                    header: {
                        className: 'bg-purple-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P3 - Total em Aberto',
                size: 170,
            }),
            columnHelper.accessor(({ VL_A_VENCER_ACORDO_P3 }) =>
                VL_A_VENCER_ACORDO_P3
                    ? currencyFormat(Number(VL_A_VENCER_ACORDO_P3))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_A_VENCER_ACORDO_P3',
                meta: {
                    header: {
                        className: 'bg-purple-100',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P3 Acordo - Total a Vencer',
                size: 200,
            }),
            columnHelper.accessor(({ VL_VENCIDO_ACORDO_P3 }) =>
                VL_VENCIDO_ACORDO_P3
                    ? currencyFormat(Number(VL_VENCIDO_ACORDO_P3))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_VENCIDO_ACORDO_P3',
                meta: {
                    header: {
                        className: 'bg-purple-100',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P3 Acordo - Total Vencido',
                size: 200,
            }),
            columnHelper.accessor(({ VL_VENCIDO_ACORDO_HOJE_P3 }) =>
                VL_VENCIDO_ACORDO_HOJE_P3
                    ? currencyFormat(Number(VL_VENCIDO_ACORDO_HOJE_P3))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_VENCIDO_ACORDO_HOJE_P3',
                meta: {
                    header: {
                        className: 'bg-purple-100',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P3 Acordo - Vencimento Hoje',
                size: 220,
            }),
            columnHelper.accessor(({ VL_JUROS_PELO_PRAZO_ACORDO_P3 }) =>
                VL_JUROS_PELO_PRAZO_ACORDO_P3
                    ? currencyFormat(Number(VL_JUROS_PELO_PRAZO_ACORDO_P3))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_JUROS_PELO_PRAZO_ACORDO_P3',
                meta: {
                    header: {
                        className: 'bg-purple-100',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P3 Acordo - Juros Pelo Prazo',
                size: 210,
            }),
            columnHelper.accessor(({ VL_MULTA_ACORDO_P3 }) =>
                VL_MULTA_ACORDO_P3
                    ? currencyFormat(Number(VL_MULTA_ACORDO_P3))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_MULTA_ACORDO_P3',
                meta: {
                    header: {
                        className: 'bg-purple-100',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P3 Acordo - Total Multa',
                size: 190,
            }),
            columnHelper.accessor(({ VL_TOTAL_COM_JUROS_ACORDO_P3 }) =>
                VL_TOTAL_COM_JUROS_ACORDO_P3
                    ? currencyFormat(Number(VL_TOTAL_COM_JUROS_ACORDO_P3))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_TOTAL_COM_JUROS_ACORDO_P3',
                meta: {
                    header: {
                        className: 'bg-purple-100',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'P3 Acordo - Total em Aberto',
                size: 210,
            }),
            columnHelper.accessor(({ EXTRATO }) =>
                EXTRATO
                    ? currencyFormat(Number(EXTRATO))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'EXTRATO',
                meta: {
                    header: {
                        className: 'bg-orange-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'Extrato',
                size: 150,
            }),
            columnHelper.accessor(({ EXTRATO_MENOS_VENCIDOS }) =>
                EXTRATO_MENOS_VENCIDOS
                    ? currencyFormat(Number(EXTRATO_MENOS_VENCIDOS))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'EXTRATO_MENOS_VENCIDOS',
                meta: {
                    header: {
                        className: 'bg-orange-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'Extrato Menos Vencidos',
                size: 190,
            }),
            columnHelper.accessor(({ EXTRATO_MENOS_VENCIDOS_E_A_VENCER }) =>
                EXTRATO_MENOS_VENCIDOS_E_A_VENCER
                    ? currencyFormat(Number(EXTRATO_MENOS_VENCIDOS_E_A_VENCER))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'EXTRATO_MENOS_VENCIDOS_E_A_VENCER',
                meta: {
                    header: {
                        className: 'bg-orange-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'Extrato Menos Venc. e Á Vencer',
                size: 230,
            }),
            columnHelper.accessor(({ TOTAL_A_VENCER }) =>
                TOTAL_A_VENCER
                    ? currencyFormat(Number(TOTAL_A_VENCER))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'TOTAL_A_VENCER',
                meta: {
                    header: {
                        className: 'bg-slate-100',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'Total a Vencer',
                size: 160,
            }),
            columnHelper.accessor(({ TOTAL_VENCIDO }) =>
                TOTAL_VENCIDO
                    ? currencyFormat(Number(TOTAL_VENCIDO))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'TOTAL_VENCIDO',
                meta: {
                    header: {
                        className: 'bg-slate-100 h-14',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'Total Vencido',
                size: 160,
            }), 
            columnHelper.accessor(({ TOTAL_EM_ABERTO }) =>
                TOTAL_EM_ABERTO
                    ? currencyFormat(Number(TOTAL_EM_ABERTO))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'TOTAL_EM_ABERTO',
                meta: {
                    header: {
                        className: 'bg-slate-100',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'Total Em Aberto',
                size: 160,
            }),
            columnHelper.accessor(({ TOTAL_A_VENCER_ACORDO }) =>
                TOTAL_A_VENCER_ACORDO
                    ? currencyFormat(Number(TOTAL_A_VENCER_ACORDO))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'TOTAL_A_VENCER_ACORDO',
                meta: {
                    header: {
                        className: 'bg-slate-200',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'Acordo - Total a Vencer',
                size: 180,
            }),
            columnHelper.accessor(({ TOTAL_VENCIDO_ACORDO }) =>
                TOTAL_VENCIDO_ACORDO
                    ? currencyFormat(Number(TOTAL_VENCIDO_ACORDO))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'TOTAL_VENCIDO_ACORDO',
                meta: {
                    header: {
                        className: 'bg-slate-200',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'Acordo - Total Vencido',
                size: 180,
            }), 
            columnHelper.accessor(({ TOTAL_EM_ABERTO_ACORDO }) =>
                TOTAL_EM_ABERTO_ACORDO
                    ? currencyFormat(Number(TOTAL_EM_ABERTO_ACORDO))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'TOTAL_EM_ABERTO_ACORDO',
                meta: {
                    header: {
                        className: 'bg-slate-200',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'Acordo - Total Em Aberto',
                size: 190,
            }),
            columnHelper.accessor(({ SITUACAO_SEM_ACORDO_LIMITE_DISPONIVEL }) =>
                SITUACAO_SEM_ACORDO_LIMITE_DISPONIVEL
                    ? currencyFormat(Number(SITUACAO_SEM_ACORDO_LIMITE_DISPONIVEL))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'SITUACAO_SEM_ACORDO_LIMITE_DISPONIVEL',
                meta: {
                    header: {
                        className: 'bg-lime-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'Sit. S/Acordo - Limite Disp.',
                size: 200,
            }),
            columnHelper.accessor(({ SITUACAO_SEM_ACORDO_A_VENCER }) =>
                SITUACAO_SEM_ACORDO_A_VENCER
                    ? currencyFormat(Number(SITUACAO_SEM_ACORDO_A_VENCER))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'SITUACAO_SEM_ACORDO_A_VENCER',
                meta: {
                    header: {
                        className: 'bg-lime-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'Sit. S/Acordo - Á Vencer',
                size: 190,
            }),
            columnHelper.accessor(({ SITUACAO_SEM_ACORDO_VENCIDO }) =>
                SITUACAO_SEM_ACORDO_VENCIDO
                    ? currencyFormat(Number(SITUACAO_SEM_ACORDO_VENCIDO))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'SITUACAO_SEM_ACORDO_VENCIDO',
                meta: {
                    header: {
                        className: 'bg-lime-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'Sit. S/Acordo - Vencido',
                size: 180,
            }),
            columnHelper.accessor(({ SITUACAO_SEM_ACORDO_TOTAL_EM_ABERTO }) =>
                SITUACAO_SEM_ACORDO_TOTAL_EM_ABERTO
                    ? currencyFormat(Number(SITUACAO_SEM_ACORDO_TOTAL_EM_ABERTO))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'SITUACAO_SEM_ACORDO_TOTAL_EM_ABERTO', 
                meta: {
                    header: {
                        className: 'bg-lime-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'Sit. S/Acordo - Total em Aberto',
                size: 230,
            }),
            columnHelper.accessor((data) =>{
                    let status = "LIBERADO"
                    let SITUACAO_SEM_ACORDO_VENCIDO = data.SITUACAO_SEM_ACORDO_VENCIDO || 0
                    let TOTAL_VENCIDO = data.TOTAL_VENCIDO || 0
                    let SITUACAO_SEM_ACORDO_LIMITE_DISPONIVEL = data.SITUACAO_SEM_ACORDO_LIMITE_DISPONIVEL || 0
                    if(SITUACAO_SEM_ACORDO_VENCIDO > 0 || TOTAL_VENCIDO > 1 || SITUACAO_SEM_ACORDO_LIMITE_DISPONIVEL < -1){
                        status = "BLOQUEADO"
                    }
                    return status
                }, {
                id: 'SITUACAO_SEM_ACORDO_STATUS',
                meta: {
                    header: {
                        className: 'bg-lime-50'
                    }
                },
                header: 'Sit. S/Acordo - STATUS',
                size: 180,
            }),
            columnHelper.accessor(({ SITUACAO_COM_ACORDO_LIMITE_DISPONIVEL }) =>
                SITUACAO_COM_ACORDO_LIMITE_DISPONIVEL
                    ? currencyFormat(Number(SITUACAO_COM_ACORDO_LIMITE_DISPONIVEL))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'SITUACAO_COM_ACORDO_LIMITE_DISPONIVEL',
                meta: {
                    header: {
                        className: 'bg-yellow-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>{header.column.columnDef.header} <p className="block mt-2">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'Sit. C/Acordo - Limite Disp.',
                size: 210,
            }),
            columnHelper.accessor((data) =>{
                    let status = "LIBERADO"
                    let TOTAL_VENCIDO = data.TOTAL_VENCIDO || 0
                    let SITUACAO_COM_ACORDO_LIMITE_DISPONIVEL = data.SITUACAO_COM_ACORDO_LIMITE_DISPONIVEL || 0
                    if(TOTAL_VENCIDO > 1 || SITUACAO_COM_ACORDO_LIMITE_DISPONIVEL < -1){
                        status = "BLOQUEADO"
                    }
                    return status
                }, {
                id: 'SITUACAO_COM_ACORDO_STATUS',
                meta: {
                    header: {
                        className: 'bg-yellow-50'
                    }
                },
                header: 'Sit. C/Acordo - STATUS',
                size: 180,
            }),
            columnHelper.accessor((data) =>{
                    let status = "FALSO"
                    let TOTAL_VENCIDO = data.SITUACAO_SEM_ACORDO_LIMITE_DISPONIVEL || 0
                    let SITUACAO_COM_ACORDO_LIMITE_DISPONIVEL = data.SITUACAO_COM_ACORDO_LIMITE_DISPONIVEL || 0
                    if(TOTAL_VENCIDO == SITUACAO_COM_ACORDO_LIMITE_DISPONIVEL){
                        status = "VERDADEIRO"
                    }
                    return status
                }, {
                id: 'SITUACAO_COM_ACORDO_CHECK_LIMITE',
                meta: {
                    header: {
                        className: 'bg-yellow-50'
                    }
                },
                header: 'Sit. C/Acordo - Check Limite',
                size: 210,
            }),
            columnHelper.accessor((data) =>{
                    let status = "NÃO"
                    let EXTRATO = data.EXTRATO || 0 
                    if(EXTRATO != 0){
                        status = "SIM"
                    }
                    return status
                }, {
                id: 'SITUACAO_COM_ACORDO_TEM_SALDO',
                meta: {
                    header: {
                        className: 'bg-yellow-50'
                    }
                },
                header: 'Sit. C/Acordo - Tem Saldo',
                size: 200,
            }),
            columnHelper.accessor((data) =>{
                    let status = "NÃO"
                    let VL_VENCIDO_P1 = data.VL_VENCIDO_P1 || 0 
                    let VL_VENCIDO_P2 = data.VL_VENCIDO_P2 || 0 
                    let VL_JUROS_PELO_PRAZO_P2 = data.VL_JUROS_PELO_PRAZO_P2 || 0 
                    let VL_MULTA_P2 = data.VL_MULTA_P2 || 0 
                    let VL_VENCIDO_P3 = data.VL_VENCIDO_P3 || 0 
                    let VL_JUROS_PELO_PRAZO_P3 = data.VL_JUROS_PELO_PRAZO_P3 || 0 
                    let VL_MULTA_P3 = data.VL_MULTA_P3 || 0 

                    if((VL_VENCIDO_P1 + VL_VENCIDO_P2 + VL_JUROS_PELO_PRAZO_P2 + VL_MULTA_P2 + VL_VENCIDO_P3 + VL_JUROS_PELO_PRAZO_P3 +VL_MULTA_P3) > 0){
                        status = "SIM"
                    }
                    return status
                }, {
                id: 'SITUACAO_COM_ACORDO_INADIMPLENCIA',
                meta: {
                    header: {
                        className: 'bg-yellow-50'
                    }
                },
                header: 'Sit. C/Acordo - Inadimplência',
                size: 215,
            }),
        ].filter(columns => {
            let visible = columnsTableVisible.filter( column => column.selected == true).map( column => column.columns).flat()
            let parameterDefault = ["DS_ROTA","NM_PROPRIETARIO","NM_MUNICIPIO","DD_CLIENTE","DS_REVENDA_GRADE","DD_ESTADO","DD_LIMITE"]
            
            visible.push(...parameterDefault)
            return visible.includes(columns.id || '')
        }),
        [columnsTableVisible]
    )
     
    const handleSetColumnsTableVisible = (parameter) => { 
        setColumnsTableVisible(prev => {
            return prev.map(objeto => {  
                
                return {
                    ...objeto,
                    selected: objeto.id === parameter.id  ? !objeto.selected : objeto.selected
                }
            });
        })
    }

  return (
    <>   
    <div className="flex flex-col h-full overflow-auto p-4">
        <Breadcrumb>
            <BreadcrumbList>
                <BreadcrumbItem>
                    <BreadcrumbLink asChild>
                        <Link
                            className="hover:underline"
                            to="/"
                        >
                            Inicio
                        </Link>
                    </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                    <BreadcrumbPage>
                        Posição Total
                    </BreadcrumbPage>
                </BreadcrumbItem>
            </BreadcrumbList>
        </Breadcrumb>

        <TypographyH4 className="mt-2 text-md">Exibir grupo de informações</TypographyH4>
        <div className="py-2 overflow-x-auto grid grid-cols-[repeat(7,minmax(150px,150px))] gap-4">  
            {columnsTableVisible?.map((detail) => (
                <Button 
                    key={detail.id}  
                    size="sm"
                    variant={detail.selected ? "default" : "outline"}
                    onClick={() => handleSetColumnsTableVisible(detail)}
                > 
                    {detail.description}
                </Button>
            ))}
        </div>  
        <div className="flex-1 overflow-hidden">
            <Table<UnsettledSecuritiesTable>
                data={memoData}
                columns={memoColumns}
                getRowId={(row) => row.DS_ROTA.toString()}
                isLoading={isPending}
                isFetching={isFetching}  
                tableActions={(
                    <div className="flex items-center h-full gap-1.5">
                        <Button
                            className="flex items-center w-full gap-4 h-[40px] justify-start text-sm hover:text-primary-500 hover:bg-accent"
                            variant={'ghost'}
                            onClick={() => table && table.resetColumnFilters()}
                        >
                            <FilterX size={14} />
                            Remover Todos os Filtros
                        </Button>
                        <ButtonWithTooltip
                            tooltipContent={<p className="text-white">Download</p>}
                            onClick={() => table && onDownload(table)}
                            variant="ghost"
                        >
                        <ArrowDownToLine size={18} />
                        </ButtonWithTooltip>
                        <div className="w-[1px] h-4 bg-neutral-300" />  
                        <ButtonWithTooltip
                            tooltipContent={<p className="text-white">Atualizar</p>}
                            onClick={() => refetch()}
                            variant="ghost"
                        >
                        <RefreshCcw size={18} />
                        </ButtonWithTooltip>
                        <div className="w-[1px] h-4 bg-neutral-300" />
                        
                    </div>
                )}  
                tableHeader 
                getTableInstance={(table) => { 
                    setTable(table)
                }} 
                tableState={tableState} 
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultColumn>{getValue() as string}</DefaultColumn>
                    ),
                }}
            /> 
        </div>
    </div> 
</>
  )
}

export default TotalPosition
