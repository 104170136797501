import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip'
import { TypographyP } from '@/components/ui/typography'
import { cn } from '@/lib/utils'
import { TableLayout } from '@/types/TableLayout'
import { EyeClosedIcon } from '@radix-ui/react-icons'
import { Globe, LayoutDashboard, MoreVertical, Trash, X } from 'lucide-react'
import { useState } from 'react'

type LayoutItemProps = {
    data: TableLayout
    self?: boolean
    onClick: () => void
    onRemove: () => void
    onUpdateVisibility: () => void
}

const LayoutItem = ({
    data,
    self,
    onClick,
    onRemove,
    onUpdateVisibility,
}: LayoutItemProps) => {
    const { DS_LAYOUT, SK_LAYOUT_TABELA, DD_PUBLICO, NOME_USUARIO } = data
    const [canRemove, setCanRemove] = useState(false)

    return (
        <div
            key={SK_LAYOUT_TABELA}
            className={cn(
                'flex items-center justify-between gap-2 px-4 py-2 cursor-pointer group ',
                canRemove
                    ? 'bg-red-50 text-red-500 hover:bg-red-100'
                    : 'hover:bg-neutral-100'
            )}
            onClick={onClick}
        >
            <div className="flex items-center gap-2">
                <LayoutDashboard className="text-primary-600" size={14} />
                <TypographyP>{DS_LAYOUT}</TypographyP>
            </div>
            {self && (
                <div className="flex items-center gap-2">
                    {canRemove ? (
                        <>
                            <button
                                className="text-xs text-neutral-500"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setCanRemove(false)
                                }}
                            >
                                Cancelar
                            </button>
                            <button
                                className="p-1 hover:bg-red-200"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onRemove()
                                }}
                            >
                                <Trash size={14} />
                            </button>
                        </>
                    ) : (
                        <>
                            {DD_PUBLICO === 1 && (
                                <TooltipProvider>
                                    <Tooltip>
                                        <TooltipTrigger>
                                            <Globe
                                                className="text-sky-500"
                                                size={14}
                                            />
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            Layout público
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            )}
                            <DropdownMenu>
                                <DropdownMenuTrigger
                                    className={cn('p-1 hover:bg-neutral-200')}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <MoreVertical size={14} />
                                </DropdownMenuTrigger>
                                <DropdownMenuContent>
                                    {DD_PUBLICO === 1 ? (
                                        <DropdownMenuItem
                                            className="gap-2"
                                            onClick={(e) => {
                                                onUpdateVisibility()
                                                e.stopPropagation()
                                            }}
                                        >
                                            <EyeClosedIcon className="text-orange-500" />
                                            Tornar privado
                                        </DropdownMenuItem>
                                    ) : (
                                        <DropdownMenuItem
                                            className="gap-2"
                                            onClick={(e) => {
                                                onUpdateVisibility()
                                                e.stopPropagation()
                                            }}
                                        >
                                            <Globe
                                                className="text-sky-500"
                                                size={14}
                                            />
                                            Tornar público
                                        </DropdownMenuItem>
                                    )}
                                </DropdownMenuContent>
                            </DropdownMenu>
                            <button
                                className="p-1 hover:bg-neutral-200"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setCanRemove(true)
                                }}
                            >
                                <X size={14} />
                            </button>
                        </>
                    )}
                </div>
            )}
            {!self && (
                <div className="flex flex-col items-end gap-1">
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger>
                                <Globe className="text-sky-500" size={14} />
                            </TooltipTrigger>
                            <TooltipContent>Layout público</TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                    <div className="text-xs text-neutral-500">
                        Criado por {NOME_USUARIO}
                    </div>
                </div>
            )}
        </div>
    )
}

export default LayoutItem
