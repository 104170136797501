import TopMessage from "@/components/TopMessage"
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
  } from "@/components/ui/card"
import  { PositionSummary as PositionSummaryProps } from '@/types/UnsettledSecurities'
import { currencyFormat } from "@/utils/stringFormatter"
 
interface SummaryProps { 
   data: PositionSummaryProps[]
   isFetching: boolean
   isPending: boolean
   type: string
   width: number
   extra: boolean
}

const PositionSummary = ({ data, isFetching, isPending, type, width, extra } : SummaryProps ) => {
    return (
        <div className="h-full overflow-auto flex flex-wrap	gap-2">
            {isFetching && !isPending ? (
                        <TopMessage
                            text="Atualizando dados..."
                            variant="loading"
                        />
                    ) : null}
            {!isPending && data.map((item, index) => (
                item.RESUMO == type && (
                <Card className={`min-w-[${width}px]`} key={index}>
                    <CardHeader className="p-4">
                        <CardTitle className="text-primary-500">{item.AGRUPAMENTO}</CardTitle> 
                    </CardHeader>
                    <CardContent className="p-4 pt-0 flex flex-col">
                        <div className="grid grid-cols-3 justify-between gap-4">
                            <div className="grid mb-2">
                                <span className="text-neutral-500 font-medium">Valor Total</span>
                                <span className="font-medium">{currencyFormat(Number(item.VL_TOTAL_COM_JUROS))}</span>
                            </div>
                            <div className="grid mb-2">
                                <span className="text-neutral-500 font-medium">Total a Vencer</span>
                                <span className="font-medium">{currencyFormat(Number(item.VL_A_VENCER))}</span>
                            </div>
                            <div className="grid mb-2">
                                <span className="text-neutral-500 font-medium">Total Vencido</span>
                                <span className="font-medium">{currencyFormat(Number(item.VL_VENCIDO))}</span>
                            </div>
                        </div>
                        {extra && (
                            <div  className="grid grid-cols-3  gap-4">
                                <div className="grid mb-2">
                                    <span className="text-neutral-500 font-medium">Total Multa</span>
                                    <span className="font-medium">{currencyFormat(Number(item.VL_MULTA))}</span>
                                </div>
                                <div className="grid mb-2">
                                    <span className="text-neutral-500 font-medium">Total Juros Pelo Prazo</span>
                                    <span className="font-medium">{currencyFormat(Number(item.VL_JUROS_PELO_PRAZO))}</span>
                                </div>
                            </div>
                        )}
                    </CardContent> 
                </Card>)
            ))}
        </div>
    )
}

export default PositionSummary
