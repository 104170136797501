import axios, { AxiosError, AxiosRequestHeaders } from 'axios'
import { getHeaders } from '@/api/utils'

const instance = axios.create({
    baseURL: import.meta.env.VITE_BUSINESS_API_BASE_URL,
})

interface CustomError {
    message?: string
}

export function isAxiosError(error: unknown): error is AxiosError<CustomError> {
    return axios.isAxiosError(error)
}

instance.interceptors.request.use((config) => {
    config.headers = {
        ...config.headers,
        ...getHeaders(),
    } as unknown as AxiosRequestHeaders

    return config
})

export default instance
