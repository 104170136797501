import { getItem } from '@/utils/storage'

export const getHeaders = () => {
    return {
        'x-access-token': getItem(localStorage, 'token'),
        'x-sistema': 'CRWEB',
        'x-sistema-legivel': 'Contas a Receber Web',
        'x-versao': import.meta.env.VITE_VERSION,
    }
}
