import instance from '@/api/business' 
import { UnsettledSecuritiesPosition1, UnsettledSecuritiesPosition2, UnsettledSecuritiesPosition3, UnsettledSecuritiesTotalPosition, PositionSummary } from './type'

const BASE_URL = '/unsettledsecurities/crweb'

export const fetchUnsettledSecuritiesPosition1 = async () => {
    const response = await instance.post<UnsettledSecuritiesPosition1[]>(`${BASE_URL}/position1`)

    return response.data
}

export const fetchUnsettledSecuritiesPosition2 = async () => {
    const response = await instance.post<UnsettledSecuritiesPosition2[]>(`${BASE_URL}/position2`)

    return response.data
}

export const fetchUnsettledSecuritiesPosition3 = async () => {
    const response = await instance.post<UnsettledSecuritiesPosition3[]>(`${BASE_URL}/position3`)

    return response.data
} 

export const fetchTotalPosition = async () => {
    const response = await instance.post<UnsettledSecuritiesTotalPosition[]>(`${BASE_URL}/totalposition`)

    return response.data
} 

export const fetchPositionSumarry = async () => {
    const response = await instance.post<PositionSummary[]>(`${BASE_URL}/positionsumarry`)

    return response.data
} 