import {
    Dialog,
    DialogContent,  
} from '@/components/ui/dialog' 
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { Movimentation } from '@/types/BankStatement'
import { currencyFormat } from '@/utils/stringFormatter'
import { useMemo } from 'react';
import { getDateFromId } from '@/utils/date'
import { format } from 'date-fns'

interface ViewPDFProps {
    isOpen: boolean
    onClose: () => void,
    data: Movimentation[]
}

const ViewPDF = ({ isOpen, onClose, data }: ViewPDFProps) => {
    const styles = StyleSheet.create({
        row: { display: 'flex', flexDirection: 'row' },
        nav: {
          width: '100%',
          backgroundColor: '#ececec',
          padding: 4, 
          marginBottom: 8,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        },
        title: {
          fontSize: 10,
          fontWeight: 'bold',
        },
        subTitle: {
          fontSize: 8,
          color: '#2b2b2b',
        },
        subTitleReduz: {
            fontSize: 7,
            color: '#2b2b2b',
          },
        containerItem: {
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
          paddingHorizontal: 8,
        },
        containerInsumos: {
          marginHorizontal: 0,
          border: '0.5px solid #dadada',
          padding: 4,
        },
        cabecalhoInsumos: {
          display: 'flex',
          flexDirection: 'row',
          borderBottom: '1px solid #dadada',
          paddingBottom: 4,
          marginBottom: 4,
        },
        itemInsumos: {
          display: 'flex',
          flexDirection: 'row',
          paddingVertical: 2,
          borderBottom: '0.5px solid #dadada',
        },
        column: {
          flex: 0.2,
          paddingHorizontal: 4,
        },
        column2: {
          flex: 0.4,
          paddingHorizontal: 4,
        },
        column3: {
            flex: 0.4,
            paddingHorizontal: 4,
            textAlign: 'right'
          },
      });

    const PDF = ({data: dados}) => {
        return (
            <Document>
                <Page size="A4" style={{ padding: 4 }}> 
                    <View>
                        <View style={styles.nav}>
                            <Text style={{ fontSize: 14 }}>Movimentações Bancárias</Text>
                        </View>
                        <View style={styles.containerItem}> 
                            
                            {dados.map((item, index) => (
                                <View key={index} style={styles.containerInsumos}>
                                    <View style={styles.nav}>
                                        <Text style={{ fontSize: 11 }}>{item.DS_ROTA}</Text>
                                        <Text style={{ fontSize: 11 }}>{item.DD_ROTA}</Text>
                                    </View>
                                    <View style={styles.cabecalhoInsumos}>
                                        <Text style={[styles.title, styles.column]}>Data</Text> 
                                        <Text style={[styles.title, styles.column]}>Cod. Nat</Text> 
                                        <Text style={[styles.title, styles.column2]}>Natureza</Text> 
                                        <Text style={[styles.title, styles.column2]}>Histórico</Text> 
                                        <Text style={[styles.title, styles.column3]}>Valor Entrada</Text>
                                        <Text style={[styles.title, styles.column3]}>Valor Saída</Text>
                                        <Text style={[styles.title, styles.column3]}>Saldo</Text>
                                    </View>
                                    {item.data.map((i,idx) =>(
                                        <View key={idx} style={styles.itemInsumos}>
                                            <Text style={[styles.subTitle, styles.column]}>
                                                {i.DATA ? format(getDateFromId(Number(i.DATA)), 'dd/MM/yyyy'): ''}
                                            </Text> 
                                            <Text style={[styles.subTitle, styles.column]}>
                                                {i.DD_CODIGO_NATUREZA_FINANCEIRA}
                                            </Text>
                                            <Text style={[styles.subTitleReduz, styles.column2]}>
                                                {i.DD_NATUREZA_FINANCEIRA}
                                            </Text>
                                            <Text style={[styles.subTitleReduz, styles.column2]}>
                                                {i.DS_HISTORICO}
                                            </Text>
                                            <Text style={[styles.subTitle, styles.column3]}>
                                                {i.ENTRADA ? currencyFormat(Number(i.ENTRADA)) : currencyFormat(0)}
                                            </Text>
                                            <Text style={[styles.subTitle, styles.column3]}>
                                                {i.SAIDA ? currencyFormat(Number(i.SAIDA)) : currencyFormat(0)}
                                            </Text>
                                            <Text style={[styles.subTitle, styles.column3]}>
                                                {i.SALDO ? currencyFormat(Number(i.SALDO)) : currencyFormat(0)}
                                            </Text>
                                        </View>
                                    ) )}
                                   
                                </View>
                            ))}
                        </View> 
                    </View>
                </Page>
            </Document>  

        )
    }

    const agrupedData = useMemo(() => {
        const DS_ROTAs = [...new Set(data.map((item)=> item.DS_ROTA))]

        const new_data = DS_ROTAs.map(ds_rota => {
            const itens = data.filter(item => item.DS_ROTA == ds_rota)
            return {
                DS_ROTA: ds_rota,
                DD_ROTA: itens[0].DD_ROTA,
                data: itens
            }
        }) 

        return new_data
    },data)

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className='max-w-[1000px] overflow-auto h-4/5'>   
                <PDFViewer className="w-full h-full pt-4">
                    <PDF data={agrupedData} />
                </PDFViewer>
            </DialogContent>
        </Dialog>
    )
}

export default ViewPDF
