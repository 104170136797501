import instance from '@/api/business' 
import { LimitBank } from './type'

const BASE_URL = '/bank/crweb/limitbank'

export const fetchLimitBank = async () => {
    const response = await instance.post<LimitBank[]>(BASE_URL)

    return response.data
}

