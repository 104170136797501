import { fetchUnsettledSecuritiesPosition2 } from '@/api/business/unsettledSecurities'
import { useQuery } from '@tanstack/react-query'

export const UnsettledSecuritiesPosition2Keys = {
    all: ['unsettledsecuritiesposition2'] as const,
    lists: () => [...UnsettledSecuritiesPosition2Keys.all, 'list'] as const,
    list: (page: number, perPage: number, filters: any) =>
        [...UnsettledSecuritiesPosition2Keys.lists(), page, perPage, filters] as const,
    details: () => [...UnsettledSecuritiesPosition2Keys.all, 'detail'] as const,
    detail: (params: string) => [...UnsettledSecuritiesPosition2Keys.details(), params] as const,
}

export const useUnsettledSecuritiesPosition2Query = () => {
    return useQuery({
        queryFn: fetchUnsettledSecuritiesPosition2,
        queryKey: UnsettledSecuritiesPosition2Keys.lists(),
    })
}
