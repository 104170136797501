import { fetchMovimentation } from '@/api/business/bankstatement'
import { useQuery } from '@tanstack/react-query'

export const BankStatementMovimentationKeys = {
    all: ['bankstatementmovimentation'] as const,
    lists: () => [...BankStatementMovimentationKeys.all, 'list'] as const,
    list: (page: number, perPage: number, filters: any) =>
        [...BankStatementMovimentationKeys.lists(), page, perPage, filters] as const,
    details: () => [...BankStatementMovimentationKeys.all, 'detail'] as const,
    detail: (params: string) => [...BankStatementMovimentationKeys.details(), params] as const,
}

export const useBankStatmentMovimentationQuery = ({X_CHAVE, X_TYPE, SK_TEMPO_INICIO, SK_TEMPO_FIM, enableStatus}) => {
    return useQuery({
        enabled: enableStatus,
        queryFn : () => fetchMovimentation({X_CHAVE, X_TYPE, SK_TEMPO_INICIO, SK_TEMPO_FIM}),
        queryKey: BankStatementMovimentationKeys.lists(),
    })
}
