import instance from '@/api/business' 
import { PropsBankStatement, PropsBankStatementOpened, Movimentation, HistoryPayment, Opened, BalanceSammary } from './type'

const BASE_URL = '/bankstatement/crweb'

export const fetchMovimentation = async (data: PropsBankStatement) => {
    const response = await instance.post<Movimentation[]>(`${BASE_URL}/movimentation`, data)

    return response.data
}

export const fetchHistoryPayment = async (data: PropsBankStatement) => {
    const response = await instance.post<HistoryPayment[]>(`${BASE_URL}/historypayments`, data)

    return response.data


}
export const fetchOpened = async (data: PropsBankStatementOpened) => {
    const response = await instance.post<Opened[]>(`${BASE_URL}/opened`, data)

    return response.data
}

export const fetchBalanceSummary = async () => {
    const response = await instance.post<BalanceSammary[]>(`${BASE_URL}/balancesummary`)

    return response.data
}

