import { cn } from '@/lib/utils'
import { VariantProps, cva } from 'class-variance-authority'
import { motion, AnimatePresence } from 'framer-motion'

type TopMessageProps = {
    text: string
    className?: string
} & VariantProps<typeof variants>

const variants = cva('mb-4 w-full rounded-md py-1 text-center text-xs', {
    variants: {
        variant: {
            success: 'bg-secondary-50 text-secondary-500',
            loading: 'bg-secondary-50 text-secondary-600',
            error: 'bg-red-50 text-red-500',
        },
    },
    defaultVariants: { variant: 'success' },
})

const TopMessage = ({ text, className, variant }: TopMessageProps) => {
    return (
        <AnimatePresence>
            <motion.div
                className={cn(variants({ variant, className }))}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
            >
                {text}
            </motion.div>
        </AnimatePresence>
    )
}

export default TopMessage
