import { createTableLayout } from '@/api/business/layoutTabela'
import Button from '@/components/Button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { TableIds, TableNames } from '@/config/table'
import { DisclosureCommonProps } from '@/types/Disclosure'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { useTableContext } from '../../context/TableContext'
import { useToast } from '@/components/ui/use-toast'
import { tableLayoutKeys } from '@/queries/useTableLayoutQuery'
import { isAxiosError } from '@/api/business'
import { useEffect } from 'react'

interface CreateLayoutDialogProps extends DisclosureCommonProps {
    tableId: TableIds
}

const createLayoutSchema = z.object({
    layoutName: z.string().min(1, 'Campo obrigatório'),
})

type CreateLayoutForm = z.infer<typeof createLayoutSchema>

const CreateLayoutDialog = ({
    tableId,
    isOpen,
    onOpenChange,
}: CreateLayoutDialogProps) => {
    const { tableState } = useTableContext()
    const { toast } = useToast()
    const queryClient = useQueryClient()

    const { mutate, isPending } = useMutation({
        mutationFn: createTableLayout,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: tableLayoutKeys.lists() })
            queryClient.invalidateQueries({
                queryKey: tableLayoutKeys.listsUser(tableId),
            })

            toast({
                title: 'Layout criado com sucesso',
                variant: 'success',
            })

            onOpenChange(false)
        },
        onError: (error) => {
            let errorMessage =
                'Houve um erro ao criar o layout. Tente novamente mais tarde.'

            if (isAxiosError(error)) {
                errorMessage = error.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao criar layout',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const form = useForm<CreateLayoutForm>({
        resolver: zodResolver(createLayoutSchema),
        defaultValues: {
            layoutName: '',
        },
    })

    const onSubmit = ({ layoutName }: CreateLayoutForm) => {
        mutate({
            layout: JSON.stringify(tableState),
            layoutName: layoutName,
            tableId,
        })
    }

    useEffect(() => {
        if (!isOpen) form.reset()
    }, [isOpen])

    return (
        <Dialog open={isOpen} onOpenChange={onOpenChange}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Criar layout</DialogTitle>
                    <DialogDescription>
                        Criar layout para tabela{' '}
                        <strong className="font-semibold">
                            {TableNames[tableId]}
                        </strong>
                    </DialogDescription>
                </DialogHeader>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-4"
                    >
                        <FormField
                            name="layoutName"
                            control={form.control}
                            render={({ field }) => {
                                return (
                                    <FormItem>
                                        <FormLabel>Descrição</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )
                            }}
                        />
                        <div className="flex justify-end gap-2">
                            <Button
                                type="button"
                                variant="ghost"
                                onClick={() => onOpenChange(false)}
                            >
                                Cancelar
                            </Button>
                            <Button className="w-[80px]" isLoading={isPending}>
                                Confirmar
                            </Button>
                        </div>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default CreateLayoutDialog
