import { useLastUpdateQuery } from '@/queries/useLastUpdateQuery'
import { getDateFromId } from '@/utils/date'
import { format } from 'date-fns'

function LastUpdate() {
    const { data } = useLastUpdateQuery()

    return (
        <>
            {data && (
                <div>
                    <span className='bg-primary-500 text-white text-xs p-[2px] px-2 rounded-md mr-1'>
                        Data base: {format(getDateFromId(Number(data[0].SK_TEMPO_BASE)), 'dd/MM/yyyy')} 
                    </span>

                    <span className='bg-primary-500 text-white text-xs p-[2px] px-2 rounded-md'>
                        Última atualização: {data[0].CREATED_AT}
                    </span> 
                </div>
            )}
        </>
  )
}

export default LastUpdate
