import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { useResaleQuery } from '@/queries/useResaleQuery'
import { Resale } from '@/types/Resale'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'
import DadosMestreHeader from '../components/DadosMestreHeader'
import TableRowActions from '@/components/Table/components/TableRowActions'
import DeleteDialog from './components/DeleteDialog'
import EditDialog from './components/EditDialog'
import AddDialog from './components/AddDialog'
import DadosMestreHeaderActions from '../components/DadosMestreHeaderActions' 
import { useResaleSpreadsheets } from './useResaleSpreadsheets' 
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { useTable } from '@/hooks/useTable'

export type ResaleTable = TableData<Resale>

const columnHelper = createColumnHelper<Resale>()

const MasterDataResale = () => {
    const {
        table, 
        selectedRow,
        selectedRows,
        isAddDialogOpen,
        isDeleteDialogOpen,
        isEditDialogOpen, 
        onAddDialogClose,
        onClickAdd,
        onClickDelete,
        onClickEdit,
        onClickMultiDelete,
        onDeleteDialogClose,
        onEditDialogClose, 
        setTable,
    } = useTable<Resale>('')

    const { onDownload } = useResaleSpreadsheets() 

    const { data, isFetching, isPending, refetch } = useResaleQuery()

    const memoData = useMemo(() => data || [], [data])
    const memoColumns = useMemo(
        () => [
            columnHelper.accessor('DS_ROTA', {
                id: 'DS_ROTA',
                header: 'Nome Rota',
                size: 200,
            }),
            columnHelper.accessor('DD_ROTA', {
                id: 'DD_ROTA',
                header: 'Cod. Rota',
                size: 110,
            }),
            columnHelper.accessor('DD_TIPO', {
                id: 'DD_TIPO',
                header: 'Tipo',
                size: 100,
            }),
            columnHelper.accessor('NM_PROPRIETARIO', {
                id: 'NM_PROPRIETARIO',
                header: 'Proprietário',
                size: 180,
            }),
            columnHelper.accessor('DD_CLIENTE', {
                id: 'DD_CLIENTE',
                header: 'Cod. Cliente',
                size: 120,
            }),
            columnHelper.accessor('DD_LOJA', {
                id: 'DD_LOJA',
                header: 'Loja',
                size: 80,
            }), 
            columnHelper.accessor('NM_CLIENTE', {
                id: 'NM_CLIENTE',
                header: 'Nome Cliente',
                size: 300,
            }), 
            columnHelper.accessor('DS_REVENDA_GRADE', {
                id: 'DS_REVENDA_GRADE',
                header: 'Revenda na Grade',
                size: 150,
            }), 
            columnHelper.accessor('NM_REVENDA', {
                id: 'NM_REVENDA',
                header: 'Nome Revenda',
                size: 200,
            }), 
            columnHelper.accessor('NM_MUNICIPIO', {
                id: 'NM_MUNICIPIO',
                header: 'Município',
                size: 200,
            }), 
            columnHelper.accessor('NM_ENDERECO', {
                id: 'NM_ENDERECO',
                header: 'Endereço',
                size: 280,
            }), 
            columnHelper.accessor('NM_BAIRRO', {
                id: 'NM_BAIRRO',
                header: 'Bairro',
                size: 250,
            }), 
            columnHelper.accessor('DD_CEP', {
                id: 'DD_CEP',
                header: 'CEP',
                size: 120,
            }), 
            columnHelper.accessor('DD_ESTADO', {
                id: 'DD_ESTADO',
                header: 'UF',
                size: 70,
            }), 
            columnHelper.accessor('DD_CNPJ', {
                id: 'DD_CNPJ',
                header: 'CNPJ',
                size: 160,
            }), 
            columnHelper.accessor('DD_APARECER_LIMITE', {
                id: 'DD_APARECER_LIMITE',
                header: 'Aparecer Limite',
                size: 140,
            }), 
            columnHelper.accessor('DD_PRAZO', {
                id: 'DD_PRAZO',
                header: 'Prazo',
                size: 80,
            }), 
            columnHelper.accessor('DD_VERSAO', {
                id: 'DD_VERSAO',
                header: 'Versão',
                size: 100,
            }), 
            columnHelper.accessor('DD_LIMITE', {
                id: 'DD_LIMITE',
                header: 'Limite',
                size: 160,
            }), 
            columnHelper.accessor('DD_TIPO_GARANTIA', {
                id: 'DD_TIPO_GARANTIA',
                header: 'Tipo Garantia',
                size: 150,
            }), 
            columnHelper.accessor('DD_VALOR', {
                id: 'DD_VALOR',
                header: 'Valor',
                size: 220,
            }), 
            columnHelper.accessor('DS_OBSERVACAO1', {
                id: 'DS_OBSERVACAO1',
                header: '1º Observação',
                size: 300,
            }), 
            columnHelper.accessor('DS_OBSERVACAO2', {
                id: 'DS_OBSERVACAO2',
                header: '2º Obervação',
                size: 300,
            }), 
            columnHelper.accessor('DD_CADASTRO_LIMITE', {
                id: 'DD_CADASTRO_LIMITE',
                header: 'Cadatro Limite',
                size: 130,
            }), 
            columnHelper.accessor('UPDATED_AT', {
                id: 'UPDATED_AT',
                header: 'Ultima Modificação',
                size: 160,
            }),  
            columnHelper.display({
                id: 'Ações',
                cell: ({ row }) => { 
                    return (
                        (
                            <TableRowActions
                                onClickDelete={() => onClickDelete(row)}
                                onClickEdit={() => onClickEdit(row)}
                            />
                        )
                    )
                },
                size: 60,
                enableColumnFilter: false,
                enableSorting: false,
            }),
        ],
        []
    )

    return (
        <div className="w-full h-full p-4 overflow-hidden">
            <Table<ResaleTable>
                data={memoData}
                columns={memoColumns}
                getRowId={(row) => row.SK_REVENDA.toString()}
                isLoading={isPending}
                isFetching={isFetching}
                getTableInstance={(table) => setTable(table)} 
                tableHeader={
                    <DadosMestreHeader
                        title={`Revendas`}
                        onDelete={onClickMultiDelete}
                        selectedRows={
                            table
                                ?.getFilteredRowModel()
                                .flatRows.filter((row) => selectedRows[row.id])
                                .length
                        }
                    />
                }
                tableActions={
                    <DadosMestreHeaderActions
                        onDownload={() => table && onDownload(table)}
                        onRefech={refetch}
                        onAdd={onClickAdd}
                    />
                } 
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultColumn>{getValue() as string}</DefaultColumn>
                    ),
                }}
            />
            {isDeleteDialogOpen && (
                <DeleteDialog
                    ids={
                        selectedRow
                            ? [selectedRow.original.SK_REVENDA]
                            : table
                            ? table
                                  .getFilteredRowModel()
                                  .flatRows.filter(
                                      (row) => selectedRows[row.id]
                                  )
                                  .map((row) => row.original.SK_REVENDA)
                            : []
                    }
                    isOpen={isDeleteDialogOpen}
                    onClose={onDeleteDialogClose}
                />
            )}
            {selectedRow && (
                <EditDialog
                    row={selectedRow}
                    isOpen={isEditDialogOpen}
                    onClose={onEditDialogClose}
                />
            )}
            <AddDialog isOpen={isAddDialogOpen} onClose={onAddDialogClose} />
        </div>
    )
}

export default MasterDataResale
