import { fetchHistoryPayment } from '@/api/business/bankstatement'
import { useQuery } from '@tanstack/react-query'

export const BankStatementHistoryPaymentKeys = {
    all: ['bankstatementhistorypayment'] as const,
    lists: () => [...BankStatementHistoryPaymentKeys.all, 'list'] as const,
    list: (page: number, perPage: number, filters: any) =>
        [...BankStatementHistoryPaymentKeys.lists(), page, perPage, filters] as const,
    details: () => [...BankStatementHistoryPaymentKeys.all, 'detail'] as const,
    detail: (params: string) => [...BankStatementHistoryPaymentKeys.details(), params] as const,
}

export const useBankStatmentHistoryPaymentQuery = ({X_CHAVE, X_TYPE, SK_TEMPO_INICIO, SK_TEMPO_FIM, enableStatus}) => {
    return useQuery({
        enabled: enableStatus,
        queryFn : () => fetchHistoryPayment({X_CHAVE, X_TYPE, SK_TEMPO_INICIO, SK_TEMPO_FIM}),
        queryKey: BankStatementHistoryPaymentKeys.lists(),
    })
}
