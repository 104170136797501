import { TableIds } from '@/config/table'
import { useTable } from '@/hooks/useTable'
import { createContext, useContext } from 'react'
import { once } from 'lodash'

type UseTableReturn<T> = ReturnType<typeof useTable<T>>

export type TableContextType<T> = UseTableReturn<T> & { tableId: TableIds }

type TableContextProps = {
    children: React.ReactNode
    tableId: TableIds
}

const createTableContext = once(<T,>() =>
    createContext<TableContextType<T>>({} as TableContextType<T>)
)

export const TableContextProvider = <T,>({
    children,
    tableId,
}: TableContextProps) => {
    const values = useTable<T>(tableId)

    const TableContext = createTableContext<T>()

    TableContext.displayName = 'TableContext'

    return (
        <TableContext.Provider value={{ ...values, tableId }}>
            {children}
        </TableContext.Provider>
    )
}

export const useTableContext = <T,>() => useContext(createTableContext<T>())
