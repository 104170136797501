import { fetchCustomer } from '@/api/business/customer'
import { useQuery } from '@tanstack/react-query'

export const CustomerKeys = {
    all: ['customer'] as const,
    lists: () => [...CustomerKeys.all, 'list'] as const,
    list: (page: number, perPage: number, filters: any) =>
        [...CustomerKeys.lists(), page, perPage, filters] as const,
    details: () => [...CustomerKeys.all, 'detail'] as const,
    detail: (params: string) => [...CustomerKeys.details(), params] as const,
}

export const useCustomerQuery = () => {
    return useQuery({
        queryFn: fetchCustomer,
        queryKey: CustomerKeys.lists(),
    })
}
