import { useBaseStore } from '@/store'
import { getInitials } from '@/utils/stringFormatter'
import { Menu } from 'lucide-react'
import { Avatar, AvatarFallback } from '../ui/avatar'
import ProfileMenu from './components/ProfileMenu'
import { TypographyP } from '../ui/typography'
import { Separator } from '../ui/separator'
import { Store } from '@/store/type'
import LastUpdate from './components/LastUpdate'

const stateSelector = (state: Store) => ({
    isSidenavOpen: state.appConfigSlice.state.isSidenavOpen,
    setSidenavOpen: state.appConfigSlice.actions.setSidenavOpen,
    user: state.authSlice.state.user
})

const Header = () => {
    const { isSidenavOpen, setSidenavOpen, user } = useBaseStore(stateSelector)
    return (
        <>
            <div className="flex items-center justify-between w-full px-4 h-header">
                <div className="flex items-center gap-4">
                    <button
                        onClick={() => setSidenavOpen(!isSidenavOpen)}
                        className="text-foreground"
                    >
                        <Menu size={18} />
                    </button>
                </div>
                <div className="flex items-center h-full gap-4 ml-auto">
                    <LastUpdate/>
                    <ProfileMenu
                        className="p-4 hover:bg-accent"
                        trigger={
                            <div className="flex items-center h-full gap-2 pointer-events-none">
                                <Avatar className="w-6 h-6 text-xs">
                                    <AvatarFallback className="bg-primary-500 text-gray-50">
                                        {getInitials(user || '')}
                                    </AvatarFallback>
                                </Avatar>
                                <TypographyP className="hidden pointer-events-none sm:block">
                                    {user}
                                </TypographyP>
                            </div>
                        }
                    />
                </div>
            </div>
            <Separator />
        </>
    )
}

export default Header
