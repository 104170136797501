import Button from '@/components/Button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog'
import { Skeleton } from '@/components/ui/skeleton'
import { TableIds, TableNames } from '@/config/table'

import {
    tableLayoutKeys,
    useTableLayoutQuery,
    useUserTableLayoutQuery,
} from '@/queries/useTableLayoutQuery'
import { DisclosureCommonProps } from '@/types/Disclosure'
import { LayoutDashboard } from 'lucide-react'
import { useTableContext } from '../../context/TableContext'
import LayoutItem from './components/LayoutItem'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
    deleteTableLayout,
    updateTableLayout,
} from '@/api/business/layoutTabela'
import { useToast } from '@/components/ui/use-toast'
import { TableLayout } from '@/types/TableLayout'
import { isAxiosError } from '@/api/business'
import TopMessage from '@/components/TopMessage'
import { TypographyP } from '../ui/typography'

interface LayoutDialogProps extends DisclosureCommonProps {
    tableId: TableIds
    onCreateDialogOpenChange: (isOpen: boolean) => void
}

const LayoutDialog = ({
    tableId,
    isOpen,
    onOpenChange,
    onCreateDialogOpenChange,
}: LayoutDialogProps) => {
    const { onTableStateChange } = useTableContext()
    const { toast } = useToast()

    const queryClient = useQueryClient()

    const tablesQueryKey = tableLayoutKeys.all
    const userQueryKey = tableLayoutKeys.listsUser(tableId)

    const { mutate: mutateDelete } = useMutation({
        mutationFn: deleteTableLayout,
        onMutate: ({ id }) => {
            queryClient.cancelQueries({ queryKey: userQueryKey })

            const prevData =
                queryClient.getQueryData<TableLayout[]>(userQueryKey)

            queryClient.setQueryData<TableLayout[]>(userQueryKey, (prev) =>
                prev?.filter((layout) => layout.SK_LAYOUT_TABELA !== id)
            )

            return { prevData }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: tablesQueryKey })
        },
        onError: (err, _, ctx) => {
            queryClient.setQueryData<TableLayout[]>(userQueryKey, ctx?.prevData)

            let errorMessage =
                'Houve um erro ao deletar o layout. Tente novamente mais tarde'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao deletar layout',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const { mutate: mutateUpdate } = useMutation({
        mutationFn: updateTableLayout,
        onMutate: (vars) => {
            queryClient.cancelQueries({ queryKey: userQueryKey })
            const prevData =
                queryClient.getQueryData<TableLayout[]>(userQueryKey)

            queryClient.setQueriesData<TableLayout[]>(
                { queryKey: userQueryKey },
                (prev) =>
                    prev?.map((layout) => {
                        if (layout.SK_LAYOUT_TABELA === vars.SK_LAYOUT_TABELA) {
                            return {
                                ...layout,
                                ...vars,
                            }
                        }

                        return layout
                    })
            )

            return prevData
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: tablesQueryKey })
        },
        onError: (err) => {
            let errorMessage = 'Houve um erro ao atualizar layout'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao atualizar layout',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const {
        data: userTableLayouts,
        isPending: isUserTableLayoutsPending,
        isError: isUserTableLayoutsError,
        isFetching: isUserTableFetching,
    } = useUserTableLayoutQuery({ tableId })

    const {
        data: tableLayouts,
        // isPending: isTableLayoutsPending,
        isFetching: isTableLayoutsFetching,
        isError: isTableLayoutsError,
    } = useTableLayoutQuery({ tableId })

    return (
        <Dialog open={isOpen} onOpenChange={onOpenChange}>
            <DialogTrigger className="flex items-center gap-2 px-4 py-2 text-sm border rounded-lg cursor-pointer hover:bg-accent">
                <LayoutDashboard className="text-sky-500" size={14} />
                <TypographyP>Layouts</TypographyP>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Layouts</DialogTitle>
                    <DialogDescription>
                        Layouts disponíveis para a tabela{' '}
                        <strong className="font-semibold">
                            {TableNames[tableId]}
                        </strong>
                    </DialogDescription>
                </DialogHeader>
                <div className="space-y-2">
                    {isUserTableFetching || isTableLayoutsFetching ? (
                        <TopMessage
                            text="Atualizando dados"
                            variant="loading"
                        />
                    ) : null}

                    {isUserTableLayoutsPending || isUserTableLayoutsError ? (
                        <Skeleton className="w-full h-6" />
                    ) : null}

                    <div>
                        <TypographyP className="font-semibold">
                            Meus layouts
                        </TypographyP>
                    </div>
                    {userTableLayouts?.length === 0 &&
                    !isUserTableLayoutsError ? (
                        <TypographyP className="text-center text-neutral-400">
                            Não há layouts criados para essa tabela. Deseja
                            salvar o atual?
                        </TypographyP>
                    ) : null}
                    <div>
                        {userTableLayouts && userTableLayouts.length > 0 ? (
                            <>
                                {userTableLayouts.map((layout) => (
                                    <LayoutItem
                                        key={layout.SK_LAYOUT_TABELA}
                                        data={layout}
                                        self
                                        onClick={() => {
                                            onTableStateChange(
                                                JSON.parse(layout.LAYOUT)
                                            )
                                            onOpenChange(false)
                                        }}
                                        onRemove={() => {
                                            mutateDelete({
                                                id: layout.SK_LAYOUT_TABELA,
                                            })
                                        }}
                                        onUpdateVisibility={() => {
                                            mutateUpdate({
                                                SK_LAYOUT_TABELA:
                                                    layout.SK_LAYOUT_TABELA,
                                                DD_PUBLICO:
                                                    layout.DD_PUBLICO === 1
                                                        ? 0
                                                        : 1,
                                            })
                                        }}
                                    />
                                ))}
                            </>
                        ) : null}
                    </div>
                    <div>
                        <TypographyP className="font-semibold">
                            Layouts compartilhados
                        </TypographyP>
                    </div>
                    {tableLayouts?.length === 0 && !isTableLayoutsError ? (
                        <TypographyP className="text-center text-neutral-400">
                            Não há layouts compartilhados para essa tabela
                        </TypographyP>
                    ) : null}
                    <div>
                        {tableLayouts && tableLayouts.length > 0 ? (
                            <>
                                {tableLayouts.map((layout) => (
                                    <LayoutItem
                                        key={layout.SK_LAYOUT_TABELA}
                                        data={layout}
                                        onClick={() => {
                                            onTableStateChange(
                                                JSON.parse(layout.LAYOUT)
                                            )
                                            onOpenChange(false)
                                        }}
                                        onRemove={() => {
                                            mutateDelete({
                                                id: layout.SK_LAYOUT_TABELA,
                                            })
                                        }}
                                        onUpdateVisibility={() => {
                                            mutateUpdate({
                                                SK_LAYOUT_TABELA:
                                                    layout.SK_LAYOUT_TABELA,
                                                DD_PUBLICO:
                                                    layout.DD_PUBLICO === 1
                                                        ? 0
                                                        : 1,
                                            })
                                        }}
                                    />
                                ))}
                            </>
                        ) : null}
                    </div>
                </div>
                <DialogFooter>
                    <Button variant="ghost" onClick={() => onOpenChange(false)}>
                        Cancelar
                    </Button>
                    <Button onClick={() => onCreateDialogOpenChange(true)}>
                        Criar layout
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default LayoutDialog
