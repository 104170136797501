import { TypographyH2, TypographyLarge, TypographyXS } from "@/components/ui/typography"
import CompleteDataBase from "./components/CompleteDataBase"  
import UnsettledSecuritiesDataBase from "./components/UnsettledSecuritiesDataBase"  
import Button from "@/components/Button"
import useDisclosure from "@/hooks/useDisclosure"
 


 const MasterDataUpdateDataBase = () => {  
    const {
        isOpen: isCompleteDataBaseDialogOpen,
        onClose: onCompleteDataBaseDialogClose,
        onOpen: onCompleteDataBaseDialogOpen,
    } = useDisclosure()
    
    const {
        isOpen: isUnsettledSecuritiesDataBaseDialogOpen,
        onClose: onUnsettledSecuritiesDataBaseDialogClose,
        onOpen: onUnsettledSecuritiesDataBaseDialogOpen,
    } = useDisclosure()

    return (
        <div className="w-full h-full p-4 overflow-hidden">
            <TypographyH2 className="content-center">Atualização da Base de Dados</TypographyH2>
            <div className="mt-4 grid grid-cols-[repeat(3,minmax(auto,320px))] gap-8">    
                <div className="flex flex-col border-gray-300 border p-3 rounded-xl">
                    <div className="flex-1"> 
                        <TypographyLarge>Atualizar Base por Completo</TypographyLarge>
                        <TypographyXS>Isso irá incluir os dados mestres, extratos da revenda, títulos em aberto, movimentações bancárias e limite bancário.</TypographyXS>
                    </div>
                    <div className="mt-1"> 
                        <Button className="block" onClick={() => onCompleteDataBaseDialogOpen()} >Atualizar</Button>
                        <CompleteDataBase  isOpen={isCompleteDataBaseDialogOpen} onClose={onCompleteDataBaseDialogClose} ></CompleteDataBase>    
                    </div>
                </div>
                <div className="border-gray-300 border p-3 rounded-xl flex flex-col">
                    <div className="flex-1">
                        <TypographyLarge>Atualizar Títulos em Aberto</TypographyLarge>
                        <TypographyXS>Isso irá atualizar apenas os títulos em aberto.</TypographyXS>
                    </div>
                    <div className="mt-1"> 
                        <Button className="block" onClick={() => onUnsettledSecuritiesDataBaseDialogOpen()} >Atualizar</Button>
                        <UnsettledSecuritiesDataBase  isOpen={isUnsettledSecuritiesDataBaseDialogOpen} onClose={onUnsettledSecuritiesDataBaseDialogClose} ></UnsettledSecuritiesDataBase>    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MasterDataUpdateDataBase
