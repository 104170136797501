import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/components/ui/use-toast'
import { deleteResale } from '@/api/business/resale'
import { ResaleKeys } from '@/queries/useResaleQuery'
import { isAxiosError } from '@/api/business'
import Button from '@/components/Button'

interface DeleteDialogProps {
    ids: (string | number)[]
    isOpen: boolean
    onClose: () => void
}

const DeleteDialog = ({ ids, isOpen, onClose }: DeleteDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const { mutate: mutateDelete, isPending: mutateLoading } = useMutation({
        mutationFn: deleteResale,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ResaleKeys.lists() })
            onClose()
            toast({
                title: 'Revenda excluida com sucesso',
            })
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível excluir a revenda. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao excluir revenda',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Excluir Revenda</DialogTitle>
                </DialogHeader>
                <div className="pt-4">
                    <p>
                        Realmente deseja excluir
                        <span className="font-bold"> {ids.length}</span> itens?
                    </p>
                </div>
                <DialogFooter>
                    <Button onClick={onClose} variant="ghost">
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => mutateDelete({ SK_REVENDA: ids })}
                        isLoading={mutateLoading}
                    >
                        Confirmar
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default DeleteDialog
