import { TypographyH4   } from "@/components/ui/typography"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
  } from "@/components/ui/tabs"
import PositionSummary from "./components/PositionSummary"
import { useUnsettledSecuritiesPositionSummaryQuery } from "@/queries/useUnsettledSecuritiesPositionSummaryQuery"
import ButtonWithTooltip from "@/components/ButtonWithTooltip"
import { RefreshCcw } from "lucide-react"

const Home = () => {

    const { data = [], isFetching, isPending, refetch } = useUnsettledSecuritiesPositionSummaryQuery() 
    
    return (
        <div className="flex flex-col h-full overflow-auto p-4">
            <TypographyH4>Seja Bem-Vindo ao Contas a Receber Web</TypographyH4>
           
           <div className="mt-4">
            <Tabs defaultValue="position1" className="">
                    <TabsList>
                        <TabsTrigger value="position1">Resumo Posição 1</TabsTrigger>
                        <TabsTrigger value="position2">Resumo Posição 2</TabsTrigger>
                        <TabsTrigger value="position3">Resumo Posição 3</TabsTrigger> 
                        <ButtonWithTooltip
                                    tooltipContent={<p className="text-white">Atualizar</p>}
                                    onClick={() => refetch()}
                                    variant="ghost"
                                >
                                    <RefreshCcw size={18} />
                                </ButtonWithTooltip>
                    </TabsList>
                    <TabsContent value="position1" className="">
                        <PositionSummary data={data} isFetching={isFetching} isPending={isPending} width={460} extra={false} type="POSITION1" />
                    </TabsContent>
                    <TabsContent value="position2">
                        <PositionSummary data={data} isFetching={isFetching} isPending={isPending} width={460} extra={true} type="POSITION2" />
                    </TabsContent>
                    <TabsContent value="position3">
                        <PositionSummary data={data} isFetching={isFetching} isPending={isPending} width={460} extra={true} type="POSITION3" />
                    </TabsContent>
                </Tabs>
           </div>

        </div>
    )
}

export default Home
