import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { useCustomerQuery } from '@/queries/useCustomerQuery'
import { Customer } from '@/types/Customer'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'
import DadosMestreHeader from '../components/DadosMestreHeader'
import DadosMestreHeaderActions from '../components/DadosMestreHeaderActions' 
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { useTable } from '@/hooks/useTable'
import { useCustomerSpreadsheets } from './useCustomerSpreadsheets'

export type CustomerTable = TableData<Customer>

const columnHelper = createColumnHelper<Customer>()

const MasterDataCustomer = () => {
    const {
        table,
        tableState,
        selectedRows, 
        onClickMultiDelete, 
        setTable,
    } = useTable<Customer>('')

    const { onDownload } = useCustomerSpreadsheets()

    const { data, isFetching, isPending, refetch } = useCustomerQuery()

    const memoData = useMemo(() => data || [], [data])
    const memoColumns = useMemo(
        () => [ 
            columnHelper.accessor('NK_CLIENTE', {
                id: 'NK_CLIENTE',
                header: 'Cod. Cliente',
                size: 120,
            }), 
            columnHelper.accessor('DD_LOJA', {
                id: 'DD_LOJA',
                header: 'Loja',
                size: 80,
            }), 
            columnHelper.accessor('DS_RAZAO_SOCIAL', {
                id: 'DS_RAZAO_SOCIAL',
                header: 'Razão Social',
                size: 300,
            }), 
            columnHelper.accessor('DS_NOME_FANTASIA', {
                id: 'DS_NOME_FANTASIA',
                header: 'Nome Fantasia',
                size: 300,
            }), 
            columnHelper.accessor('DD_CEP', {
                id: 'DD_CEP',
                header: 'CEP',
                size: 110,
            }), 
            columnHelper.accessor('DD_ESTADO', {
                id: 'DD_ESTADO',
                header: 'UF',
                size: 70,
            }), 
            columnHelper.accessor('DD_MUNICIPIO', {
                id: 'DD_MUNICIPIO',
                header: 'Municipio',
                size: 200,
            }), 
            columnHelper.accessor('DD_BAIRRO', {
                id: 'DD_BAIRRO',
                header: 'Bairro',
                size: 240,
            }), 
            columnHelper.accessor('DD_ENDERECO', {
                id: 'DD_ENDERECO',
                header: 'Endereço',
                size: 300,
            })
        ],
        []
    )

    return (
        <div className="w-full h-full p-4 overflow-hidden">
            <Table<CustomerTable>
                data={memoData}
                columns={memoColumns}
                getRowId={(row) => row.SK_CLIENTE.toString()}
                isLoading={isPending}
                isFetching={isFetching}
                getTableInstance={(table) => setTable(table)}   
                tableState={tableState}  
                tableHeader={
                    <DadosMestreHeader
                        title={`Clientes`}
                        onDelete={onClickMultiDelete} 
                        selectedRows={
                            table
                                ?.getFilteredRowModel()
                                .flatRows.filter((row) => selectedRows[row.id])
                                .length
                        }
                    />
                }
                tableActions={
                    <DadosMestreHeaderActions 
                        onRefech={refetch}
                        onDownload={() => table && onDownload(table)}
                    />
                } 
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultColumn>{getValue() as string}</DefaultColumn>
                    ),
                }}
            /> 
        </div>
    )
}

export default MasterDataCustomer
