import instance from '@/api/business' 
import { UpdateDatabase } from './type'
 
const BASE_URL = '/update/crweb/update/database'

export const fetchUpdateDataBase = async (data : UpdateDatabase) => {
    const response = await instance.patch(BASE_URL, data)

    return response.data
}

