import {
    Dialog,
    DialogContent,  
} from '@/components/ui/dialog' 
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { BalanceSammary } from '@/types/BankStatement'
import { currencyFormat } from '@/utils/stringFormatter'

interface ViewPDFProps {
    isOpen: boolean
    onClose: () => void,
    data: BalanceSammary[]
}

const ViewPDF = ({ isOpen, onClose, data }: ViewPDFProps) => {
    const styles = StyleSheet.create({
        row: { display: 'flex', flexDirection: 'row' },
        nav: {
          width: '100%',
          backgroundColor: '#ececec',
          padding: 4,
          marginTop: 16,
          marginBottom: 8,
        },
        title: {
          fontSize: 12,
          fontWeight: 'bold',
        },
        subTitle: {
          fontSize: 8,
          color: '#2b2b2b',
        },
        containerItem: {
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          paddingHorizontal: 8,
        },
        containerInsumos: {
          marginHorizontal: 4,
          border: '0.5px solid #dadada',
          padding: 4,
        },
        cabecalhoInsumos: {
          display: 'flex',
          flexDirection: 'row',
          borderBottom: '1px solid #dadada',
          paddingBottom: 4,
          marginBottom: 4,
        },
        itemInsumos: {
          display: 'flex',
          flexDirection: 'row',
          paddingVertical: 2,
          borderBottom: '0.5px solid #dadada',
        },
        column: {
          flex: 1,
          paddingHorizontal: 4,
        },
        column2: {
          flex: 0.25,
          paddingHorizontal: 4,
        },
        column3: {
            flex: 0.5,
            paddingHorizontal: 4,
            textAlign: 'right'
          },
      });

    const PDF = ({data: dados}) => {
        return (
            <Document>
                <Page size="A4" style={{ padding: 10 }}> 
                    <View>
                        <View style={styles.nav}>
                            <Text style={{ fontSize: 14 }}>Resumo do Saldo Bancário</Text>
                        </View>
                        <View style={styles.containerItem}> 
                            <View style={styles.containerInsumos}>
                                <View style={styles.cabecalhoInsumos}>
                                <Text style={[styles.title, styles.column2]}>Rota</Text>
                                <Text style={[styles.title, styles.column]}>Revenda</Text>
                                <Text style={[styles.title, styles.column3]}>Valor Entrada</Text>
                                <Text style={[styles.title, styles.column3]}>Valor Saída</Text>
                                <Text style={[styles.title, styles.column3]}>Saldo</Text>
                                </View>
                            {dados.map((item, index) => (
                                <View key={index} style={styles.itemInsumos}>
                                    <Text style={[styles.subTitle, styles.column2]}>
                                        {item.DD_ROTA}
                                    </Text>
                                    <Text style={[styles.subTitle, styles.column]}>
                                        {item.DS_ROTA}
                                    </Text>
                                    <Text style={[styles.subTitle, styles.column3]}>
                                        {item.ENTRADA ? currencyFormat(Number(item.ENTRADA)) : currencyFormat(0)}
                                    </Text>
                                    <Text style={[styles.subTitle, styles.column3]}>
                                        {item.SAIDA ? currencyFormat(Number(item.SAIDA)) : currencyFormat(0)}
                                    </Text>
                                    <Text style={[styles.subTitle, styles.column3]}>
                                        {item.SALDO ? currencyFormat(Number(item.SALDO)) : currencyFormat(0)}
                                    </Text>
                                </View>
                            ))}
                            </View>
                        </View>
                        </View>
                </Page>
            </Document>  

        )
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className='max-w-[1000px] overflow-auto h-4/5'>   
                <PDFViewer className="w-full h-full pt-4">
                    <PDF data={data} />
                </PDFViewer>
            </DialogContent>
        </Dialog>
    )
}

export default ViewPDF
