import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from '@/pages/Login'
import NotFound from '@/pages/NotFound'
import PublicLayout from '@/pages/Layouts/PublicLayout'
import ResetPassword from '@/pages/ResetPassword'  
import RequireAuth from '@/middlewares/RequireAuth'
import MainLayout from './pages/Layouts/MainLayout' 
import Home from './pages/Home'
import Position1 from './pages/UnsettledSecurities/Position1'
import Position2 from './pages/UnsettledSecurities/Position2'
import Position3 from './pages/UnsettledSecurities/Position3'
import TotalPosition from './pages/UnsettledSecurities/TotalPosition'
import Resale from './pages/MasterData/Resale'
import Customer from './pages/MasterData/Customer'
import Product from './pages/MasterData/Product'
import UpdateDataBase from './pages/Settings/Update' 
import LimitBank from './pages/LimitBank'
import Movimentation from './pages/BankStatement/Movimentation' 
import HistoryPayment from './pages/BankStatement/HistoryPayments'
import Opened from './pages/BankStatement/Opened'
import BalanceSummary from './pages/BankStatement/BalanceSummary'

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<PublicLayout />}>
                    <Route path="*" element={<NotFound />} />
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/recuperar-senha"
                        element={<ResetPassword />}
                    />
                </Route>
                <Route element={<MainLayout />}>
                    <Route
                        path="/" 
                        element={
                            <RequireAuth isAllowed>
                                <Home />
                            </RequireAuth>
                        }
                    > 
                    </Route> 

                    <Route path='bonds'>
                        <Route
                            path="position1"
                            element={
                                <RequireAuth isAllowed> 
                                    <Position1 /> 
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="position2"
                            element={
                                <RequireAuth isAllowed> 
                                    <Position2 /> 
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="position3"
                            element={ 
                                <RequireAuth isAllowed> 
                                    <Position3 />  
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="totalposition"
                            element={ 
                                <RequireAuth isAllowed> 
                                    <TotalPosition />  
                                </RequireAuth>
                            }
                        />
                       
                    </Route>
                    <Route path="masterdata"> 
                        <Route
                            path="resale"
                            element={ 
                                <RequireAuth isAllowed> 
                                    <Resale />  
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="customer"
                            element={ 
                                <RequireAuth isAllowed> 
                                    <Customer />  
                                </RequireAuth>
                            }
                        /> 
                        <Route
                            path="product"
                            element={ 
                                <RequireAuth isAllowed> 
                                    <Product />  
                                </RequireAuth>
                            }
                        /> 
                    </Route>
                    <Route path='settings'>
                        <Route
                             path='update'
                            element={ 
                                <RequireAuth isAllowed> 
                                        <UpdateDataBase />  
                                </RequireAuth>
                            }
                        />
                    </Route>
                    <Route path='bank'>
                        <Route
                            path='limitbank'
                            element={ 
                                <RequireAuth isAllowed>  
                                    <LimitBank />   
                                </RequireAuth>
                            }
                        />
                    </Route>
                    <Route path='bankstatement'>
                        <Route
                             path='movimentation'
                            element={ 
                                <RequireAuth isAllowed>  
                                    <Movimentation />  
                                </RequireAuth>
                            }
                        />
                        <Route
                             path='historypayments'
                            element={ 
                                <RequireAuth isAllowed>  
                                    <HistoryPayment />  
                                </RequireAuth>
                            }
                        />
                        <Route
                             path='opened'
                            element={ 
                                <RequireAuth isAllowed>  
                                    <Opened />  
                                </RequireAuth>
                            }
                        />
                        <Route
                             path='balancesummary'
                            element={ 
                                <RequireAuth isAllowed>  
                                    <BalanceSummary />  
                                </RequireAuth>
                            }
                        />

                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default Router
