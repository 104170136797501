import instance from '@/api/business' 
import { LastUpdate } from './type'
 
const BASE_URL = '/settings/crweb/settings/lastupdate'

export const fetchLastUpdate = async () => {
    const response = await instance.post<LastUpdate[]>(BASE_URL)

    return response.data
}

