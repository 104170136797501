import { fetchLimitBank } from '@/api/business/bank'
import { useQuery } from '@tanstack/react-query'

export const LimitBankKeys = {
    all: ['limitbank'] as const,
    lists: () => [...LimitBankKeys.all, 'list'] as const,
    list: (page: number, perPage: number, filters: any) =>
        [...LimitBankKeys.lists(), page, perPage, filters] as const,
    details: () => [...LimitBankKeys.all, 'detail'] as const,
    detail: (params: string) => [...LimitBankKeys.details(), params] as const,
}

export const useLimitBankQuery = () => {
    return useQuery({
        queryFn: fetchLimitBank,
        queryKey: LimitBankKeys.lists(),
    })
}
