export const TableNames: Record<TableIds, string> = {
    TABLE_UNSETTLED_SECURITIES_POSITION_1: 'Titulos Posição 1',
    TABLE_UNSETTLED_SECURITIES_POSITION_2: 'Titulos Posição 2',
    TABLE_UNSETTLED_SECURITIES_POSITION_3: 'Titulos Posição 3',
    TABLE_TOTAL_POSITION: 'Titulos Posição Total',
    TABLE_RESALE: 'Revenda',
    TABLE_CUSTOMER: 'Cliente',
    TABLE_PRODUCT: 'Produto',
    TABLE_LIMIT_BANK: 'Limite banco',
    TABLE_BANK_STATEMENT_MOVIMENTATION: 'Extrato de movimentação da Revenda'
} as const

export type TableIds =
    | 'TABLE_UNSETTLED_SECURITIES_POSITION_1' 
    | 'TABLE_UNSETTLED_SECURITIES_POSITION_2' 
    | 'TABLE_UNSETTLED_SECURITIES_POSITION_3' 
    | 'TABLE_TOTAL_POSITION' 
    | 'TABLE_RESALE'
    | 'TABLE_CUSTOMER' 
    | 'TABLE_PRODUCT' 
    | 'TABLE_LIMIT_BANK'
    | 'TABLE_BANK_STATEMENT_MOVIMENTATION'
