import { fetchResale } from '@/api/business/resale'
import { useQuery } from '@tanstack/react-query'

export const ResaleKeys = {
    all: ['resale'] as const,
    lists: () => [...ResaleKeys.all, 'list'] as const,
    list: (page: number, perPage: number, filters: any) =>
        [...ResaleKeys.lists(), page, perPage, filters] as const,
    details: () => [...ResaleKeys.all, 'detail'] as const,
    detail: (params: string) => [...ResaleKeys.details(), params] as const,
}

export const useResaleQuery = () => {
    return useQuery({
        queryFn: fetchResale,
        queryKey: ResaleKeys.lists(),
    })
}
