import { useState } from 'react'

const useDisclosure = (initialOpen?: boolean) => {
    const [isOpen, setIsOpen] = useState(initialOpen || false)

    const onClose = () => setIsOpen(false)
    const onOpen = () => setIsOpen(true)
    const onToggle = () => setIsOpen((prev) => !prev)
    const onOpenChange = (isOpen: boolean) => setIsOpen(isOpen)

    return {
        isOpen,
        onOpen,
        onClose,
        onToggle,
        onOpenChange,
    }
}

export default useDisclosure
