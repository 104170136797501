import instance from '@/api/business' 
import { Customer } from './type'

const BASE_URL = '/customer/crweb/customer'

export const fetchCustomer = async () => {
    const response = await instance.post<Customer[]>(BASE_URL)

    return response.data
}

