import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { useBankStatmentOpenedQuery } from '@/queries/useBankStatementOpenedQuery'
import { useRoutsResaleQuery } from '@/queries/useRoutsResaleQuery'
import { useOwnerResaleQuery } from '@/queries/usOwnerResaleQuery'
import { Opened } from '@/types/BankStatement'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'   
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import Button from "@/components/Button"
import ButtonWithTooltip from "@/components/ButtonWithTooltip"
import { ArrowDownToLine, FilterX, RefreshCcw, Search, ChevronsUpDown } from 'lucide-react'
import { TypographyH4 } from '@/components/ui/typography'
import { 
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '@/components/ui/breadcrumb' 
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "@/components/ui/select"
import { Link } from 'react-router-dom' 
import { currencyFormat } from '@/utils/stringFormatter'
import { getDateFromId } from '@/utils/date'
import { format } from 'date-fns'
import { useTable } from '@/hooks/useTable'
import { Combobox } from '@/components/Combobox'
import { useToast } from '@/components/ui/use-toast'
import { useOpenedSpreadsheets } from './useOpenedSpreadsheets'

export type OpenedTable = TableData<Opened>

const columnHelper = createColumnHelper<Opened>()

const BankStatementOpened = () => {
    const {
        table,  
        setTable,
    } = useTable<Opened>('') 
    const { toast } = useToast()

    const [enableStatus, setEnableStatus] = useState(false)
    const [selectResale, setSelectResale] = useState<string>('')
    const [selectOwner, setSelectOwner] = useState<string>('')
    const [selectType, setSelectType] = useState<string>('Revenda')
    const typesSelect = ["Proprietário","Revenda"]
  
    const { onDownload } = useOpenedSpreadsheets()

    const handleFetchMovimentation = () => { 
        if(verifyVariablesForFetchIsCorrety() == false) return

        if(enableStatus){
            setEnableStatus(true)
        } else {
            refetch()
        }
    }

    const verifyVariablesForFetchIsCorrety = () => {
        if (selectResale == '' && selectType == 'Revenda'){
            toast({
                title: 'Preenchimento de parâmetros.',
                variant:'destructive',
                description:
                    'Necessário selecionar uma Revenda!',
            })
            return false
        } else if (selectOwner == '' && selectType == 'Proprietário'){
            toast({
                title: 'Preenchimento de parâmetros.',
                variant:'destructive',
                description:
                    'Necessário selecionar um Proprietário!',
            })
            return false
        }
        return true
    }

    const { data, isFetching, isLoading, refetch } = useBankStatmentOpenedQuery({X_CHAVE: selectType == 'Revenda' ? selectResale : selectOwner, X_TYPE: selectType, enableStatus})
    const { data: routsResale  } = useRoutsResaleQuery()
    const { data: ownerResale  } = useOwnerResaleQuery()
     
    const memoData = useMemo(() => data || [], [data])
    const memoColumns = useMemo(
        () => [ 
            columnHelper.accessor('DS_ROTA', {
                id: 'DS_ROTA',
                header: 'Revenda',
                size: 260,
            }),  
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                id: 'Empresa',
                header: 'Empresa',
                size: 120,
            }),  
            columnHelper.accessor('DD_NUMERO_DOCUMENTO', {
                id: 'DD_NUMERO_DOCUMENTO',
                header: 'Num. Documento',
                size: 150,
            }),  
            columnHelper.accessor(({ SK_EMISSAO }) =>
                SK_EMISSAO
                    ? format(getDateFromId(Number(SK_EMISSAO)), 'dd/MM/yyyy')
                    : '', {
                sortingFn: (rowA, rowB, _columnId) => {  
                    const statusA = Number(rowA.original[_columnId])
                    const statusB = Number(rowB.original[_columnId])
                    return statusA < statusB ? 1 : -1
                },
                id: 'SK_EMISSAO',
                header: 'Dt. Emissão',
                size: 120,
            }), 
            columnHelper.accessor(({ SK_VENCIMENTO_REAL }) =>
                SK_VENCIMENTO_REAL
                    ? format(getDateFromId(Number(SK_VENCIMENTO_REAL)), 'dd/MM/yyyy')
                    : '', {
                sortingFn: (rowA, rowB, _columnId) => {  
                    const statusA = Number(rowA.original[_columnId])
                    const statusB = Number(rowB.original[_columnId])
                    return statusA < statusB ? 1 : -1
                },
                id: 'SK_VENCIMENTO_REAL',
                header: 'Dt. Vencimento',
                size: 135,
            }),  
            columnHelper.accessor('DD_DIAS_JUROS', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                id: 'DD_DIAS_JUROS',
                header: 'Dias Juros',
                size: 110,
            }),   
            columnHelper.accessor(({ VL_A_VENCER }) =>
                VL_A_VENCER
                    ? currencyFormat(Number(VL_A_VENCER))
                    : currencyFormat(0), {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_A_VENCER',
                header: 'Valor a Vencer',
                size: 150,
            }),  
            
            columnHelper.accessor(({ VL_VENCIDO }) =>
                VL_VENCIDO
                    ? currencyFormat(Number(VL_VENCIDO))
                    : currencyFormat(0), {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_VENCIDO',
                header: 'Vl. Vencido',
                size: 150,
            }),  
            columnHelper.accessor(({ VL_JUROS_PELO_PRAZO }) =>
                VL_JUROS_PELO_PRAZO
                    ? currencyFormat(Number(VL_JUROS_PELO_PRAZO))
                    : currencyFormat(0), {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_JUROS_PELO_PRAZO',
                header: 'Vl. Juros',
                size: 150,
            }),  
            columnHelper.accessor(({ VL_MULTA }) =>
                VL_MULTA
                    ? currencyFormat(Number(VL_MULTA))
                    : currencyFormat(0), {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_MULTA',
                header: 'Vl. Multa',
                size: 150,
            }),     
            columnHelper.accessor('DD_IDCARGA', {
                id: 'DD_IDCARGA',
                header: 'ID Carga',
                size: 130,
            }),   
             
        ],
        []
    )

    return (
        <>
            <div className="flex flex-col h-full overflow-auto p-4">
                <Breadcrumb>
                    <BreadcrumbList>
                        <BreadcrumbItem>
                            <BreadcrumbLink asChild>
                                <Link
                                    className="hover:underline"
                                    to="/"
                                >
                                    Inicio
                                </Link>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbPage>
                                Extrato Revenda - Títulos em Aberto Posição 3
                            </BreadcrumbPage>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>
                <div className='w-full px-4 pt-2 flex gap-4'> 
                    <div className="space-y-2 flex flex-col mt-2 w-[140px]">
                        <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">Filtrar por</label> 
                        <Select onValueChange={(value) => setSelectType(value)} defaultValue={selectType}>
                            <SelectTrigger> 
                                <SelectValue />
                            </SelectTrigger>  
                            <SelectContent className="w-[200px]">
                            {typesSelect?.map((option, index) => (  
                                <SelectItem key={index} value={option}>{option}</SelectItem> 
                            ))}
                            </SelectContent>
                        </Select>
                    </div>
                    {
                        selectType == 'Revenda'? (
                            <div className="space-y-2 flex flex-col mt-2 w-[240px]">
                                <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">Revenda</label> 
                                {routsResale && (
                                    <Combobox  
                                        data={routsResale?.map(item => {return {value: item.X_CHAVE_REVENDA, label: item.DS_ROTA }})} 
                                        title="Rota" 
                                        titleSelect='Seleciona uma rota' 
                                        getSelectData={(data)=> {
                                            setSelectResale(data)
                                        }}
                                    />
                                )} 
                            </div>
                        ) : ''
                    }
                    {
                        selectType == 'Proprietário'? (
                            <div className="space-y-2 flex flex-col mt-2 w-[240px]">
                                <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">Proprietário</label> 
                                {ownerResale && (
                                    <Combobox  
                                        data={ownerResale?.map(item => {return {value: item.NM_PROPRIETARIO, label: item.NM_PROPRIETARIO }})} 
                                        title="Rota" 
                                        titleSelect='Seleciona um proprietário' 
                                        getSelectData={(data)=> {
                                            setSelectOwner(data)
                                        }}
                                    />
                                )} 
                            </div>
                        ) : ''
                    }
                    <div className="space-y-2 flex flex-col mt-2 justify-end"> 
                        <Button  
                            size="sm" 
                            onClick={() => handleFetchMovimentation()}
                        > 
                            <Search className='h-[18px]'/>
                            Pesquisar 
                        </Button>
                    </div>

                </div>
                <div className="w-full h-full p-4 overflow-hidden">
                    <Table<OpenedTable>
                        data={memoData}
                        columns={memoColumns}
                        isLoading={isLoading}
                        isFetching={isFetching}
                        tableState={{grouping: ['DS_ROTA']}}
                        expandAllRows={true}
                        getTableInstance={(table) => setTable(table)}   
                        tableHeader={(
                            <TypographyH4>Títulos em Aberto</TypographyH4>
                        )}  
                        tableActions={(
                            <div className="flex items-center h-full gap-1.5">
                                <Button
                                    className="flex items-center w-[220px] gap-4 h-[40px] justify-start text-sm hover:text-primary-500 hover:bg-accent"
                                    variant={'ghost'}
                                    onClick={() => table && table.resetColumnFilters()}
                                >
                                    <FilterX size={14} />
                                    Remover Todos os Filtros
                                </Button>
                                <div className="w-[1px] h-4 bg-neutral-300" />  
                                <Button 
                                    className="flex items-center w-[225px] gap-4 h-[40px] justify-start text-sm hover:text-primary-500 hover:bg-accent"
                                    variant={'ghost'}
                                    onClick={() => table && table.toggleAllRowsExpanded()}
                                >
                                    <ChevronsUpDown size={14}/>
                                    Expandir Todas as Colunas
                                </Button>
                                <div className="w-[1px] h-4 bg-neutral-300" />  
                                <ButtonWithTooltip
                                    tooltipContent={<p className="text-white">Download</p>}
                                    onClick={() => table && onDownload(table)} 
                                    variant="ghost"
                                >
                                <ArrowDownToLine size={18} />
                                </ButtonWithTooltip>
                                <div className="w-[1px] h-4 bg-neutral-300" />  
                                <ButtonWithTooltip
                                    tooltipContent={<p className="text-white">Atualizar</p>}
                                    onClick={() => refetch()}
                                    variant="ghost"
                                >
                                <RefreshCcw size={18} />
                                </ButtonWithTooltip>
                                <div className="w-[1px] h-4 bg-neutral-300" />
                                
                            </div>
                        )}     
                        defaultColumn={{
                            cell: ({ getValue }) => (
                                <DefaultColumn>{getValue() as string}</DefaultColumn>
                            ),
                        }}
                    /> 
                </div>
            </div>
        </>
    )
}

export default BankStatementOpened
