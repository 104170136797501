import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { BalanceSammary } from '@/types/BankStatement'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'   
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import Button from "@/components/Button"
import ButtonWithTooltip from "@/components/ButtonWithTooltip"
import { ArrowDownToLine, FilterX, RefreshCcw,  ChevronsUpDown, FileText } from 'lucide-react'
import { TypographyH4 } from '@/components/ui/typography'
import { 
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '@/components/ui/breadcrumb' 
import { Link } from 'react-router-dom' 
import { currencyFormat } from '@/utils/stringFormatter'
import { getDateFromId } from '@/utils/date'
import { format } from 'date-fns'
import { useTable } from '@/hooks/useTable'
import { useBalanceSammarySpreadsheets } from './useBalanceSammarySpreadsheets'
import { useBankStatmentBalanceSammaryQuery } from '@/queries/useBankStatementBalanceSummaryQuery'
import ViewPDF from './viewPDF'
import useDisclosure from '@/hooks/useDisclosure'

export type BalanceSammaryTable = TableData<BalanceSammary>

const columnHelper = createColumnHelper<BalanceSammary>()

const BankStatementBalanceSummary = () => {
    const {
        table,  
        setTable,
    } = useTable<BalanceSammary>('')  
  
    const {
        isOpen: isDialogOpen,
        onClose: onDialogClose,
        onOpen: onDialogOpen,
    } = useDisclosure()

    const { onDownload } = useBalanceSammarySpreadsheets()
  
    const { data, isFetching, isLoading, refetch } = useBankStatmentBalanceSammaryQuery()
  
    const memoData = useMemo(() => data || [], [data])
    const memoColumns = useMemo(
        () => [ 
            columnHelper.accessor(({ DATA }) =>
                DATA
                    ? format(getDateFromId(Number(DATA)), 'dd/MM/yyyy')
                    : '', {
                sortingFn: (rowA, rowB, _columnId) => {  
                    const statusA = Number(rowA.original[_columnId])
                    const statusB = Number(rowB.original[_columnId])
                    return statusA < statusB ? 1 : -1
                },
                id: 'DATA',
                header: 'Data',
                size: 100,
            }), 
            columnHelper.accessor('DD_ROTA', {
                id: 'DD_ROTA',
                header: 'Rota',
                size: 80,
            }),   
            columnHelper.accessor('DS_ROTA', {
                id: 'DS_ROTA',
                header: 'Revenda',
                size: 300,
            }),   
            columnHelper.accessor('NM_PROPRIETARIO', {
                id: 'NM_PROPRIETARIO',
                header: 'Proprietário',
                size: 300,
            }),   
            columnHelper.accessor('DS_HISTORICO', {
                id: 'DS_HISTORICO',
                header: 'Histórico',
                size: 150,
            }),  
            columnHelper.accessor(({ ENTRADA }) =>
                ENTRADA
                    ? currencyFormat(Number(ENTRADA))
                    : currencyFormat(0), {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'ENTRADA',
                header: 'Valor Entrada',
                size: 150,
            }),  
            
            columnHelper.accessor(({ SAIDA }) =>
                SAIDA
                    ? currencyFormat(Number(SAIDA))
                    : currencyFormat(0), {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'SAIDA',
                header: 'Valor Saída',
                size: 150,
            }),  
            columnHelper.accessor(({ SALDO }) =>
                SALDO
                    ? currencyFormat(Number(SALDO))
                    : currencyFormat(0), {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'SALDO',
                header: 'Valor Saldo',
                size: 150,
            }),  
             
        ],
        []
    )

    return (
        <>
            <div className="flex flex-col h-full overflow-auto p-4">
                <Breadcrumb>
                    <BreadcrumbList>
                        <BreadcrumbItem>
                            <BreadcrumbLink asChild>
                                <Link
                                    className="hover:underline"
                                    to="/"
                                >
                                    Inicio
                                </Link>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbPage>
                                Extrato Revenda - Resumo do Saldo Bancária Posição 3
                            </BreadcrumbPage>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb> 
                <div className="w-full h-full p-4 overflow-hidden">
                    <Table<BalanceSammaryTable>
                        data={memoData}
                        columns={memoColumns}
                        isLoading={isLoading}
                        isFetching={isFetching} 
                        expandAllRows={true}
                        getTableInstance={(table) => setTable(table)}   
                        tableHeader={(
                            <TypographyH4>Saldo Bancário</TypographyH4>
                        )}  
                        tableActions={(
                            <div className="flex items-center h-full gap-1.5">
                                <Button
                                    className="flex items-center w-[220px] gap-4 h-[40px] justify-start text-sm hover:text-primary-500 hover:bg-accent"
                                    variant={'ghost'}
                                    onClick={() => table && table.resetColumnFilters()}
                                >
                                    <FilterX size={14} />
                                    Remover Todos os Filtros
                                </Button>
                                <div className="w-[1px] h-4 bg-neutral-300" />  
                                <Button 
                                    className="flex items-center w-[225px] gap-4 h-[40px] justify-start text-sm hover:text-primary-500 hover:bg-accent"
                                    variant={'ghost'}
                                    onClick={() => table && table.toggleAllRowsExpanded()}
                                >
                                    <ChevronsUpDown size={14}/>
                                    Expandir Todas as Colunas
                                </Button> 
                                <div className="w-[1px] h-4 bg-neutral-300" /> 
                                <ButtonWithTooltip
                                    tooltipContent={<p className="text-white">Visualizar PDF</p>}
                                    variant={'ghost'}
                                    onClick={() => onDialogOpen()}
                                >
                                    <FileText size={14} />
                                    
                                </ButtonWithTooltip>
                                <div className="w-[1px] h-4 bg-neutral-300" />  
                                <ButtonWithTooltip
                                    tooltipContent={<p className="text-white">Download</p>}
                                    onClick={() => table && onDownload(table)}
                                    variant="ghost"
                                >
                                <ArrowDownToLine size={18} />
                                </ButtonWithTooltip>
                                <div className="w-[1px] h-4 bg-neutral-300" />  
                                <ButtonWithTooltip
                                    tooltipContent={<p className="text-white">Atualizar</p>}
                                    onClick={() => refetch()}
                                    variant="ghost"
                                >
                                <RefreshCcw size={18} />
                                </ButtonWithTooltip>
                                <div className="w-[1px] h-4 bg-neutral-300" />
                                
                            </div>
                        )}     
                        defaultColumn={{
                            cell: ({ getValue }) => (
                                <DefaultColumn>{getValue() as string}</DefaultColumn>
                            ),
                        }}
                    /> 
                </div> 
                {data && (<ViewPDF data={data} isOpen={isDialogOpen} onClose={onDialogClose} />)}
               
            </div>
        </>
    )
}

export default BankStatementBalanceSummary
