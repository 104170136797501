import { SidenavItems } from '../../consts'
import { NavLink, useLocation } from 'react-router-dom'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { TypographyP } from '@/components/ui/typography'
import { cn } from '@/lib/utils'

type SidenavItemProps = SidenavItems

const stateSelector = (state: Store) => ({
    isSidenavOpen: state.appConfigSlice.state.isSidenavOpen,
    isMobile: state.appConfigSlice.state.isMobile,
    setSidenavOpen: state.appConfigSlice.actions.setSidenavOpen,
    onToggleSidenavItemState:
        state.appConfigSlice.actions.onToggleSidenavItemState,
})

const toggleSelector = (id: number) => (state: Store) =>
    state.appConfigSlice.state.sidenavItemsToggleState[id]

const SidenavItem = ({ Icon, label, to, subItems, id }: SidenavItemProps) => {
    const {
        setSidenavOpen: setIsSidenavOpen,
        isSidenavOpen,
        isMobile,
        onToggleSidenavItemState,
    } = useBaseStore(stateSelector)
    const { pathname } = useLocation()

    const toggleState = useBaseStore(toggleSelector(id))

    const isActive = to && pathname == to

    return (
        <>
            {!subItems && to ? (
                <NavLink
                    className={cn(
                        'flex items-center hover:bg-primary-700  group hover:text-white rounded-md text-neutral-900 text-sm ',
                        isActive ? 'text-primary active' : '',
                        isSidenavOpen ? 'p-2 gap-4' : `justify-center py-2`
                    )}
                    to={to}
                    onClick={() => {
                        if (isMobile) setIsSidenavOpen(false)
                    }}
                >
                    {Icon ? (
                        <div className="p-1">
                            <Icon
                                size={18}
                                className={cn(
                                    'text-neutral-900 group-hover:text-white',
                                    isActive && 'text-primary'
                                )}
                            />
                        </div>
                    ) : (
                        <span className="sidenavItem__no-icon group-hover:bg-white"></span>
                    )}
                    <span className="overflow-hidden whitespace-nowrap  text-ellipsis">
                        {isSidenavOpen && label}
                    </span>
                </NavLink>
            ) : (
                <>
                    <div
                        className={` ${
                            isSidenavOpen ? 'p-2 gap-4' : `justify-center py-2`
                        } flex items-center hover:bg-primary-700 group hover:text-white rounded-md cursor-pointer`}
                        onClick={() => {
                            if (!isSidenavOpen) {
                                setIsSidenavOpen(true)
                            }

                            onToggleSidenavItemState(id.toString())
                        }}
                    >
                        {Icon ? (
                            <div className="p-1">
                                <Icon size={18} className="text-neutral-900 group-hover:text-white" />
                            </div>
                        ) : (
                            <span className="sidenavItem__no-icon"></span>
                        )}
                        {isSidenavOpen && (
                            <TypographyP className="flex-1 overflow-hidden whitespace-nowrap text-ellipsis group-hover:text-white text-neutral-700">
                                {label}
                            </TypographyP>
                        )}
                        {isSidenavOpen && subItems ? (
                            <>
                                {toggleState ? (
                                    <ChevronDown
                                        size={16}
                                        className="text-neutral-700 group-hover:text-white"
                                    />
                                ) : (
                                    <ChevronUp
                                        size={16}
                                        className="text-neutral-700 group-hover:text-white"
                                    />
                                )}
                            </>
                        ) : null}
                    </div>
                    <div
                        className={`${
                            toggleState ? 'block overflow-hidden' : 'hidden'
                        }`}
                    >
                        {subItems && isSidenavOpen
                            ? subItems.map((subItem) => (
                                  <div
                                      key={subItem.id}
                                      className="relative overflow-hidden rounded-md hover:bg-primary-700 pl-11"
                                  >
                                      <SidenavItem
                                          id={subItem.id}
                                          label={subItem.label}
                                          to={subItem.to}
                                          subItems={subItem.subItems}
                                      />
                                  </div>
                              ))
                            : null}
                    </div>
                </>
            )}
        </>
    )
}

export default SidenavItem
